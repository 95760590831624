import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { BasicTable } from '../../../components/templates/BasicTable';
import type { TableProps } from 'antd';
import { Checkbox } from 'antd';
import { IEnhancedSupporter, ISupporterOfCourseDemo } from '../../../constants/types';
import moment from 'moment';
import { SUPPORTER_APPLICATION_ACCEPTED } from '../../../constants';
interface Props {
  dataTable: ISupporterOfCourseDemo[];
  selectedRowKeys: { [key: string]: React.Key[] };
  setSelectedRowKeys: Dispatch<SetStateAction<{ [key: string]: React.Key[] }>>;
  demoId: number;
  demoStartAt: string;
  demoUrl: string;
  txtErrorUrl: string | null;
  supporterAccept: ISupporterOfCourseDemo[];
  isSPAll: boolean;
}

const OnlineAssignment = ({
  dataTable,
  selectedRowKeys,
  setSelectedRowKeys,
  demoId,
  demoStartAt,
  demoUrl,
  txtErrorUrl,
  supporterAccept,
  isSPAll,
}: Props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [supporterData, setSupporterData] = useState<IEnhancedSupporter[]>();

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(prevState => ({
      ...prevState,
      [demoId]: newSelectedRowKeys,
    }));
  };

  const addUrl = (url: string) => {
    setSelectedRowKeys(prevState => ({
      ...prevState,
      [`demo_url_${demoId}`]: [url],
    }));
  };

  const columns: TableProps<IEnhancedSupporter>['columns'] = [
    {
      title: 'サポーターID',
      dataIndex: 'id',
      key: 'id',
      render: id => (
        <div className="text-color-blue-700 font-medium">{id}</div>
      ),
    },
    {
      title: 'ユーザーID',
      dataIndex: 'username',
      key: 'username',
      render: username => (
        <div className="text-color-gray-80 font-semibold">{username}</div>
      ),
    },
    {
      title: '申請件数（未確定）',
      dataIndex: 'pending',
      key: 'pending',
    },
    {
      title: '確定件数',
      dataIndex: 'approved',
      key: 'approved',
    },
  ];

  useEffect(() => {
    const data = dataTable.map((demo, index) => {
      return {
        key: demo?.user?.id,
        id: demo?.user?.id,
        username: demo?.user?.username,
        pending: demo?.pending,
        approved: demo?.approved,
        status: demo?.status,
      };
    });
    setSupporterData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTable]);

  return (
    <div
      className="border border-color-blue-200 rounded p-4 mb-4 last:mb-0"
      key={demoId}
    >
      <div className="font-semibold text-color-blue-500 leading-4 border-b border-b-color-blue-200 pb-4 mb-4 flex justify-between items-center">
        {/* <div>
        {'日時' +
          (index + 1) +
          '：' +
          moment(demo.start_at).format('YYYY/MM/DD[_]HH時mm分')}
      </div> */}
        <div>
          {'日時' +
            (1) +
            '：' +
            moment(demoStartAt).format('YYYY/MM/DD[_]HH時mm分')}
        </div>
        <div>
          <input
            type="text"
            className="bg-white rounded-md border border-solid border-color-gray-03 placeholder:text-color-gray-50 hover:border-color-blue-400 hover:outline-0 focus:border-color-blue-700 
          focus:shadow-[0 0 2px rgba(202,223,255,0.7)]  focus-visible:border-color-blue-700 focus-visible:outline-0 px-3 text-base py-[6px] w-[400px] text-color-blue-300"
            placeholder="Zoom meeting URL"
            defaultValue={demoUrl}
            onChange={e => {
              addUrl(e.target.value);
            }}
          />
          {
            txtErrorUrl && (
              <>
                <div
                  className={`text-xs text-color-red-04`}
                  style={{ fontWeight: 500}}
                >
                  {txtErrorUrl}
                </div>
              </>
            )
          }
        </div>
      </div>
      <BasicTable
        columns={columns}
        data={supporterData}
        rowSelection={{
          selectedRowKeys: selectedRowKeys[demoId] || [],
          onChange: (newSelectedRowKeys: React.Key[]) =>
            onSelectChange(newSelectedRowKeys),
          columnTitle: () => (
            <span className="text-nowrap">
              {
                dataTable?.length > 0 ?
                (
                  <>
                    <Checkbox
                      indeterminate={
                        selectedRowKeys[demoId]?.length > 0 &&
                        selectedRowKeys[demoId]?.length < supporterAccept?.length
                      }
                      checked={
                        selectedRowKeys[demoId]?.length === supporterAccept?.length
                      }
                      onChange={e => {
                        onSelectChange(
                          e.target.checked
                            ? supporterAccept?.map((supporter : ISupporterOfCourseDemo) => supporter.user.id)
                            : []
                        );
                      }

                      }
                      disabled={isSPAll}
                    >
                      <span className="text-color-gray-600">確定メール</span>
                    </Checkbox>
                  </>
                )
                :
                (
                  <span className="text-color-gray-600">確定メール</span>
                )
              }
            </span>
          ),
          getCheckboxProps: (record: any) => ({
            disabled: record.status === SUPPORTER_APPLICATION_ACCEPTED,
          }),
        }}
        pageSize={10}
        totalData={dataTable?.length}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPage={Math.ceil(dataTable?.length / 10)}
      />
    </div>
  );
};
export default OnlineAssignment;
