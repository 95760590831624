import { TableProps } from "antd";
import { BasicTable } from "../../components/templates/BasicTable";
import { Heading } from "../../components/templates/Heading";
import { TabLinks } from "../../components/templates/TabLinks";
import { IContact, IMailTemplate } from "../../constants/types";
import { faPen, faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from "react";
import ContactService from "../../services/Contact";
import { useLocation } from "react-router-dom";
import { Loader } from "../../components/atoms/Loader";
import Button from "../../components/atoms/Button";
import ContactModalRequest from "./included/ContactModalRequest";
import { BasicModalConfirm } from "../../components/molecules/BasicModalConfirm";
import { showToastMessage } from "../../utils/common";
import AuthService from "../../services/AuthService";
import { CS_ROLES } from "../../constants";
import { systemError, txtDeleteContactError, txtDeleteContactSuccess } from "../../constants/message";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Contact = () => {
    const location = useLocation();
    const params = new URLSearchParams(window.location.search);
    const [contactData, setContactData] = useState<any>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const [isOpenModalDelete, setIsOpenModalDelete] = useState<boolean>(false);
    const [selectedData, setSelectedData] = useState<IContact | null>(null);
    const [role] = useState<number>(AuthService.getUserRole() ?? 0);

    const handleChange = async (data: any) => {
        setSelectedData(data);
        setIsOpenModal(true);
    }

    const handleContactDelete = async (data: any) => {
        setSelectedData(data);
        setIsOpenModalDelete(true);
    }

    const handleConfirmDelete = async () => {
        try {
            if (selectedData) {
                ContactService.deleteContact(selectedData.id).then(
                    response => {
                        if (response?.status === 204) {
                            showToastMessage('success', '', txtDeleteContactSuccess);
                            reloadData();
                        } else {
                            showToastMessage('error', '', txtDeleteContactError);
                        }
                    },
                )
            }
        } catch (error) {
            showToastMessage('error', '', systemError);
        } finally {
            setIsOpenModalDelete(false);
        }
    }

    const handleCloseConfirmDelete = async () => {
        setSelectedData(null);
        setIsOpenModalDelete(false);
    }

    const reloadData = () => {
        setIsLoading(true);
        ContactService.getListContact({ page: Number(params?.get('page')) || 1 }).then(
            response => {
                if (response?.data) {
                    setContactData(response?.data);
                    setIsLoading(false)
                }
            },
        );
    }

    const columns: TableProps<IMailTemplate>['columns'] = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            render: title => <span>{title}</span>,
        },
        {
            title: 'フォーム名',
            dataIndex: 'title',
            key: 'title',
            render: title => <span>{title}</span>,
        },
        {
            title: 'フォームURL',
            dataIndex: 'url',
            key: 'url',
            render: url => (
                <>
                    <a
                        href={url || '#'}
                        className="text-base text-color-blue-500 underline"
                        target="_blank"
                        rel="noreferrer"
                    >
                        {url}
                    </a>
                </>),
        },
        {
            title: 'アクション',
            dataIndex: '',
            key: 'action',
            width: 140,
            render: data => (
                <>
                    <button
                        type="button"
                        onClick={() => {
                            handleChange(data);
                        }}
                        className="rounded-md border-0 p-0 font-normal inline-block text-lg leading-9 w-9 text-gray-500"
                    >
                        <FontAwesomeIcon icon={faPen}/>
                    </button>
                    <button
                        type="button"
                        onClick={() => {
                            handleContactDelete(data);
                        }}
                        className="rounded-md border-0 p-0 font-normal inline-block text-lg leading-9 w-9 text-gray-500"
                    >
                        <FontAwesomeIcon icon={faTrash} />
                    </button>
                </>
            ),
        },
    ];

    const userColumns: TableProps<IMailTemplate>['columns'] = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            render: title => <span>{title}</span>,
        },
        {
            title: 'フォーム名',
            dataIndex: '',
            key: 'title',
            render: data => (
                <>
                    <a
                        href={data.url || '#'}
                        className="text-base text-color-blue-500 underline"
                        target="_blank"
                        rel="noreferrer"
                    >
                        {data.title}
                    </a>
                </>),
        }
    ];

    useEffect(() => {
        reloadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <>
                <div className="max-w-full">
                    <TabLinks className="mb-4" />
                    <div className="px-8 mb-24">
                        <div className="border border-color-gray-40 bg-white p-4 rounded ">
                            <div className="flex justify-between items-center mb-4">
                                <Heading className="text-color-blue-800 mb-6">
                                    問い合わせ
                                </Heading>
                                {role === CS_ROLES.admin && (
                                    <div className="flex justify-between mb-4">
                                        <Button
                                            type="button"
                                            variant="tertiary"
                                            size="small"
                                            iconPrefix={faPlusCircle}
                                            onClick={() => {
                                                setSelectedData(null);
                                                setIsOpenModal(true);
                                            }}
                                        >
                                            新規作成
                                        </Button>
                                    </div>
                                )}
                            </div>
                            <div>
                                <Loader isLoading = {isLoading}>
                                <BasicTable
                                    columns={role === CS_ROLES.admin ? columns : userColumns}
                                    data={contactData?.data}
                                    pageSize={contactData?.page_size}
                                    totalData={contactData?.total_records}
                                    currentPage={contactData?.current_page}
                                    setCurrentPage={setCurrentPage}
                                    totalPage={contactData?.total_page}
                                /></Loader>
                            </div>
                        </div>
                    </div>
                </div>
            {isOpenModal && (
                <ContactModalRequest
                    isOpenModal={isOpenModal}
                    setIsOpenModal={setIsOpenModal}
                    reloadData={reloadData}
                    selectedData={selectedData}
                    setSelectedData={setSelectedData}
                />
            )}

            <BasicModalConfirm
                title="確認"
                open={isOpenModalDelete}
                onOk={handleConfirmDelete}
                onCancel={handleCloseConfirmDelete}
                centered
                footer={[
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <Button
                                type="button"
                                variant="outline-tertiary"
                                className="w-full"
                                onClick={handleCloseConfirmDelete}
                            >
                                キャンセル
                            </Button>
                        </div>
                        <div>
                            <Button
                                type="button"
                                variant="primary"
                                className="w-full"
                                onClick={handleConfirmDelete}
                                disabled={!isOpenModalDelete}
                            >
                                OK
                            </Button>
                        </div>
                    </div>,
                ]}
            >
                {`このフォームを削除してよろしいですか？`}
            </BasicModalConfirm>
        </>
    )
}

export default Contact;
