import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faRotateRight,
  faPencil,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';
import { ISecion, ISubs } from '../../../constants/types';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { IPropsEvaluation, splitAndMapValue } from '..';
import { displayTypeSection, findObjectIndex, replaceHTML } from '../../../utils/common';

const TabContent2 = (props: IPropsEvaluation) => {
  const {
    courseInfo
  } = props;

  return (
    <div className="flex flex-col gap-4">
      <table className="w-full bg-white border border-blue-100 rounded-lg shadow-lg text-sm">
        <tr className="text-color-blue-600 h-14 align-middle text-md">
          <th className="p-4 text-left bg-blue-50 border-b border-r border-blue-100 w-1/5 font-semibold">目的</th>
          <td className="p-4 text-left bg-blue-50 border-b border-r border-blue-100"
            dangerouslySetInnerHTML={{
              __html: replaceHTML(courseInfo?.objective),
            }}>
          </td>
        </tr>
        <tr className="text-color-blue-600 h-14 align-middle">
          <th className="p-4 text-left bg-blue-50 border-b border-r border-blue-100 w-1/5">クラスの達成目標</th>
          <td className="p-4 text-left bg-blue-50 border-b border-r border-blue-100"
            dangerouslySetInnerHTML={{
              __html: replaceHTML(courseInfo?.goal),
            }}>
          </td>
        </tr>
        <tr className="text-color-blue-600 h-14 align-middle">
          <th className="p-4 text-left bg-blue-50 border-b border-r border-blue-100 w-1/5">目標達成の測定方法</th>
          <td className="p-4 text-left bg-blue-50 border-b border-r border-blue-100"
            dangerouslySetInnerHTML={{
              __html: replaceHTML(courseInfo?.method),
            }}>
          </td>
        </tr>
      </table>

      <table className="border-separate rounded border-spacing-0 w-full border border-color-blue-100 text-sm table-group-column">
        <thead>
          <tr className="bg-color-blue-50 text-color-blue-600 font-semibold">
            <td className="border-r border-r-color-blue-100 p-4 h-14 align-middle">
              構成
            </td>
            <td className="border-r border-r-color-blue-100 p-4 h-14 align-middle">
              内容
            </td>
            <td className="border-r border-r-color-blue-100 p-4 h-14 align-middle">
              方法
            </td>
            <td className="p-4 h-14 align-middle last:border-r-0">
              学生の活動
            </td>
          </tr>
        </thead>
        <tbody>
          {courseInfo?.sections?.map((section: ISecion, index: number) => {
            return (
              <>
                {section?.subs?.map((sub: ISubs, subIndex: number) => {
                  return (
                    <>
                      <tr className="bg-color-gray-20" key={`sub_${subIndex}`}>
                        {subIndex === 0 && (
                          <td
                            width="100px"
                            className="border-r border-r-color-blue-100 px-4 py-2 h-24 align-top text-color-blue-900 font-semibold"
                            rowSpan={section?.subs?.length}
                          >
                            {displayTypeSection(
                              courseInfo,
                              index,
                              courseInfo?.sections[index].type,
                            )}
                            <br />
                            {section?.subs?.[0].duration}
                          </td>
                        )}
                        <td
                          width="18.7%"
                          className="border-r border-r-color-blue-100 px-4 py-2 h-24 align-top text-color-blue-900"
                        >
                          {splitAndMapValue(sub?.content)}
                        </td>
                        <td
                          width="26%"
                          className="border-r border-r-color-blue-100 px-4 py-2 h-24 align-top text-color-blue-900"
                        >
                          {splitAndMapValue(sub?.method)}
                        </td>
                        <td className="px-4 py-2 h-24 align-top text-color-blue-900 last:border-r-0">
                          {splitAndMapValue(sub?.activity)}
                        </td>
                      </tr>
                    </>
                  );
                })}
              </>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export default TabContent2;
