import { useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types';
import { BasicDropdown } from "./Dropdown";
import TextField from "../atoms/TextField";
import { faClock } from '@fortawesome/free-regular-svg-icons';
import TimePickerV2 from "./TimePickerV2";
import SimpleBar from "simplebar-react";
import Button from '../../components/atoms/Button';
import { resetTimeSection } from "../../utils/common";

const CustomTimePicker = ({ onChange, fields, reset, val, indSection, indSubSection, id, textNote, onError, readOnly }: any) => {
    const [defaultValue] = useState<string>(val);
    const divRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (event.target.tagName == 'INPUT') {
                [`.minute-active-${indSection}-${indSubSection}`, `.second-active-${indSection}-${indSubSection}`].forEach(selector => {
                    let element = document.querySelector(selector);
                    if (element) {
                        element.scrollIntoView({
                            behavior: "smooth",
                            block: "nearest",
                            inline: "center",
                        });
                    }
                });
            } else {
                if (divRef.current && !divRef.current.contains(event.target as Node)) {
                    // onChange(defaultValue);
                    let newData = resetTimeSection(fields, false);
                    reset(
                        newData,
                        {
                            keepErrors: true,
                            keepDirty: true,
                            keepIsSubmitted: false,
                            keepTouched: false,
                            keepIsValid: false,
                            keepSubmitCount: false,
                        }
                    );
                }
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };

    }, [fields]);

    const timeOptions = (
        <>
            <div className="inline-block w-auto bg-white shadow-cs-2 pt-2" ref={divRef}>
                <div className="flex gap-x-2 pb-5">
                    <SimpleBar forceVisible="y" autoHide={true} className="h-[352px]">
                        <div className="w-[72px] pe-2 box-border">
                            <TimePickerV2
                                isSecond={false}
                                onChange={onChange}
                                val={val}
                                indSection={indSection}
                                indSubSection={indSubSection}
                            />
                        </div>
                    </SimpleBar>
                    <SimpleBar forceVisible="y" autoHide={true} className="h-[352px]">
                        <div className="w-16 pe-2 box-border">
                            <TimePickerV2
                                isSecond={true}
                                onChange={onChange}
                                val={val}
                                indSection={indSection}
                                indSubSection={indSubSection}
                            />
                        </div>
                    </SimpleBar>
                </div>
                {/* <div className="mx-2 py-2 flex justify-end border-t border-t-color-gray-02">
                    <Button
                        className="min-w-15"
                        type="button"
                        variant="primary"
                        size="small"
                        onClick={() => {
                            let newData = resetTimeSection(fields, false);
                            reset(
                                newData,
                                {
                                    keepErrors: true,
                                    keepDirty: true,
                                    keepIsSubmitted: false,
                                    keepTouched: false,
                                    keepIsValid: false,
                                    keepSubmitCount: false,
                                }
                            );
                        }}
                    >
                        OK
                    </Button>
                </div> */}
            </div>
        </>
    );
    return (
        <>
            <BasicDropdown
                overlay={timeOptions}
                trigger="click"
                placement="bottomLeft"
                disabled={readOnly}
            >
                <TextField
                    placeholder="07:00"
                    size="small"
                    iconRight={faClock}
                    classNameChild={`w-full !py-[4px] ${readOnly ? '' : '!bg-transparent'}`}
                    value={val ? val : '00:00'}
                    onChange={onChange}
                    id={id}
                    onerror={onError}
                    textNote={textNote}
                    isViewOnlySelectTime={readOnly}
                    readOnly={true}
                />
            </BasicDropdown>
        </>
    );
}

CustomTimePicker.propTypes = {
    onChange: PropTypes.func,
    fields: PropTypes.any,
    reset: PropTypes.func,
    val: PropTypes.string,
    indSection: PropTypes.any,
    indSubSection: PropTypes.any,
    id: PropTypes.any,
    textNote: PropTypes.any,
    onError: PropTypes.bool,
    readOnly: PropTypes.bool
};

CustomTimePicker.defaultProps = {
    onChange: () => { },
    fields: {},
    reset: () => { },
    val: '',
    indSection: '',
    indSubSection: '',
    id: null,
    textNote: '',
    onError: false,
    readOnly: false
};


export default CustomTimePicker;

