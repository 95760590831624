import { Heading } from '../../components/templates/Heading';
import { TabLinks } from '../../components/templates/TabLinks';
import Label from '../../components/atoms/Label';
import TextField from '../../components/atoms/TextField';
import Status from '../../components/atoms/Status';
import Button from '../../components/atoms/Button';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import VideoAssignment from './included/VideoAssignment';
import OnlineAssignment from './included/OnlineAssignment';
import DatePicker from 'antd/lib/date-picker';
import type { DatePickerProps } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CourseService from '../../services/CourseService';
import {
  IBusyShema,
  ICalendar,
  ICSAssignmentData,
  ISupporterOfCourseDemo,
} from '../../constants/types';
import { Loader } from '../../components/atoms/Loader';
import { BasicModal } from '../../components/molecules/BasicModal';
import { regexRouter, showToastMessage } from '../../utils/common';
import {
  sendDataFalse,
  successRejectCSDemo,
  systemError,
  txtChangeStatusAssignSupporterSuccess,
  txtNotHasSupporterOfDemo,
  txtSendMaiAssignSupporterSuccess,
  urlRequired,
} from '../../constants/message';
import { RouteKey, rc } from '../../constants/router';
import {
  dateFormat,
  DEMO_TYPE_OFFLINE,
  DEMO_TYPE_ONLINE,
  SUPPORTER_APPLICATION_ACCEPTED,
} from '../../constants';
import { getCalendarService } from '../../services/Calendar';
import axios from 'axios';
import momentTimeZone from 'moment-timezone';
import { useForm } from 'react-hook-form';
import TextFieldForm from '../../components/atoms/TextFieldForm';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';

const MockClassAssignment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { handleSubmit, register, watch, reset } = useForm();
  const { courseId, demoId } = useParams<{
    courseId: string;
    demoId: string;
  }>();
  const [assignmentInfo, setAssignmentInfo] =
    useState<ICSAssignmentData | null>(null);

  const [selectedRowKeys, setSelectedRowKeys] = useState<{
    [key: string]: React.Key[];
  }>({});
  const [fbDeadline, setFbDeadline] = useState<string | null>(null);
  const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSendMail, setIsSendMail] = useState<boolean>(false);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [calendars, setCalendars] = useState<ICalendar[]>([]);
  const [txtErrorUrl, setTxtErrorUrl] = useState<string | null>(null);
  const [supporterAccept, setSupporterAccept] = useState<
    ISupporterOfCourseDemo[]
  >([]);
  const [isSPAll, setisSPAll] = useState<boolean>(false);
  const [confirmReject, setConfirmReject] = useState<boolean>(false);
  const [statusUploadVideo, setStatusUploadVideo] =
    useState<string>('in_progress');
  const [linkUrl, setLinkUrl] = useState<string | null>(null);
  const [isUploadingYoutube, setIsUploadingYoutube] = useState<boolean>(false);

  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    if (typeof dateString === 'string') {
      setFbDeadline(dateString);
    } else {
      setAssignmentInfo(null);
    }
  };

  const handleCancel = () => {
    reset({ reason: '' });
    setConfirmReject(false);
  };
  const handleReject = async () => {
    if (courseId && demoId) {
      setIsLoading(true);
      setConfirmReject(false);
      try {
        const result: any = await CourseService.rejectCourseDemo(
          courseId,
          demoId,
          watch('reason'),
        );
        if (result?.status === 200) {
          if (assignmentInfo?.demo_type === 1) {
            await deleteEvent(true);
          }
          await showToastMessage(
            'success',
            '',
            successRejectCSDemo(assignmentInfo?.course?.title || ''),
          );
          navigate(regexRouter(rc(RouteKey.Home).path));
        } else {
          showToastMessage('error', systemError, '');
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    }
  };

  const showModalConfirmSendMail = (e: any) => {
    if (
      assignmentInfo?.demo_type === DEMO_TYPE_ONLINE &&
      selectedRowKeys[`demo_url_${demoId}`] &&
      selectedRowKeys[`demo_url_${demoId}`][0] === ''
    ) {
      e.preventDefault();
      setTxtErrorUrl(urlRequired);
    } else {
      setIsSendMail(true);
      setIsModalConfirmOpen(true);
      setTxtErrorUrl(null);
    }
  };

  const showModalConfirmChangeStatus = () => {
    setIsSendMail(false);
    setIsModalConfirmOpen(true);
  };

  const handleOkConfirm = async () => {
    setIsModalConfirmOpen(false);
    setIsLoading(true);
    await submitAssignmentData();
    setIsSendMail(false);
    setIsLoading(false);
  };
  const handleCancelConfirm = () => {
    setIsModalConfirmOpen(false);
    setIsSendMail(false);
  };

  const getStatusUploadVideo = (status: string) => {
    const statusMapping: any = {
      success: {
        color: 'text-green-600 bg-green-100 border-green-300',
        message: 'Upload Success',
        icon: <CheckCircleOutlined />,
      },
      failed: {
        color: 'text-red-600 bg-red-100 border-red-300',
        message: 'Upload Fail',
        icon: <CloseCircleOutlined />,
      },
      in_progress: {
        color: 'text-yellow-600 bg-yellow-100 border-yellow-300',
        message: 'Uploading to Youtube',
        icon: <SyncOutlined spin />,
      },
    };

    const { color, message, icon } =
      statusMapping[status] || statusMapping.in_progress;

    return (
      <div className={`rounded-md px-2 py-[11px] mt-6 border ${color}`}>
        {icon}
        <span className="ml-2 mr-2 text-sm">{message}</span>
      </div>
    );
  };

  useEffect(() => {
    getCalendarService().then(result => {
      setCalendars(result);
    });
  }, []);

  useEffect(() => {
    const fetch = async () => {
      await CourseService.supporterAssignment(
        courseId || '',
        demoId || '',
      ).then(result => {
        setAssignmentInfo(result.data);
        setFbDeadline(result.data && result.data.fb_deadline);
        setSelectedRowKeys(prevState => ({
          ...prevState,
          [`demo_url_${demoId}`]: [result?.data?.demo_url ?? ''],
        }));
        setSupporterAccept(
          result.data?.supporters.filter(
            (supporter: ISupporterOfCourseDemo) =>
              supporter?.status !== SUPPORTER_APPLICATION_ACCEPTED,
          ),
        );
        setisSPAll(
          result.data?.supporters.every(
            (supporter: ISupporterOfCourseDemo) =>
              supporter.status === SUPPORTER_APPLICATION_ACCEPTED,
          ),
        );
      });
    };

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId, isSubmit]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await CourseService.getStatusUploadVideoRequest(demoId || '').then(
          result => {
            const status = result.data.upload_status;
            if (
              status === 'success' ||
              status === 'failed' ||
              assignmentInfo?.demo_type === DEMO_TYPE_ONLINE
            ) {
              setIsUploadingYoutube(false);
              clearInterval(interval);
            } else {
              setIsUploadingYoutube(true);
            }
            setStatusUploadVideo(status);
            setLinkUrl(result.data.demo_url);
          },
        );
      } catch (error) {}
    };
    if (assignmentInfo?.demo_url?.includes('youtube-in-progress')) {
      setIsUploadingYoutube(true);
    }
    fetchData();

    const interval = setInterval(() => {
      fetchData();
    }, 5000);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const deleteEvent = async (isButtonReject: boolean) => {
    try {
      calendars?.forEach(async (calendar: any) => {
        await axios
          .post(
            'https://oauth2.googleapis.com/token',
            {
              client_id: process.env.REACT_APP_GG_CLIENT_ID,
              client_secret: process.env.REACT_APP_CLIENT_SERCET,
              grant_type: 'refresh_token',
              refresh_token: calendar?.refresh_token,
            },
            {
              headers: {
                'content-type': 'application/x-www-form-urlencoded',
              },
            },
          )
          .then(async res => {
            const startEventUnselect = isButtonReject
              ? assignmentInfo?.start_at
              : assignmentInfo?.unselect_demos?.[0]?.start_at;
            const endEventUnselect = isButtonReject
              ? assignmentInfo?.end_at
              : assignmentInfo?.unselect_demos?.[0]?.end_at;
            const timeStartRequestDemoReject = momentTimeZone(
              startEventUnselect,
            )
              .set({ hour: 0, minute: 0, second: 0 })
              .tz('Asia/Tokyo', true)
              .format();
            const timeEndRequestDemoReject = momentTimeZone(endEventUnselect)
              .set({ hour: 23, minute: 59, second: 59 })
              .tz('Asia/Tokyo', true)
              .format();

            const listEvent = await axios.get(
              `https://www.googleapis.com/calendar/v3/calendars/${calendar?.calendar_id}/events`,
              {
                headers: {
                  Authorization: `Bearer ${res?.data?.access_token}`,
                },
                params: {
                  singleEvents: true,
                  fields: 'items(id,start,end,summary,recurrence),summary',
                  timeMin: timeStartRequestDemoReject,
                  timeMax: timeEndRequestDemoReject,
                  maxResults: 999,
                  q: '模擬授業',
                },
              },
            );
            const dateSelect = listEvent?.data?.items?.filter(
              (it: IBusyShema) => {
                const startTimeCalendar = momentTimeZone.tz(
                  it?.start?.date ?? it?.start?.dateTime,
                  'Asia/Tokyo',
                );
                const endTimeCalendar = momentTimeZone.tz(
                  it?.end?.date ?? it?.end?.dateTime,
                  'Asia/Tokyo',
                );
                const startCsDate = momentTimeZone.tz(
                  startEventUnselect,
                  'Asia/Tokyo',
                );
                const endCsDate = momentTimeZone.tz(
                  endEventUnselect,
                  'Asia/Tokyo',
                );
                const isSameStartTime = startCsDate.isSame(startTimeCalendar);
                const isSameEndTime = endCsDate.isSame(endTimeCalendar);
                return (
                  isSameStartTime &&
                  isSameEndTime &&
                  it.summary.includes('【模擬授業】')
                );
              },
            );

            if (dateSelect.length > 0) {
              for (const data of dateSelect) {
                await axios.delete(
                  `https://www.googleapis.com/calendar/v3/calendars/${listEvent?.data?.summary}/events/${data?.id}`,
                  {
                    headers: {
                      Authorization: `Bearer ${res?.data?.access_token}`,
                      'Content-Type': 'application/json',
                    },
                  },
                );
              }
            }
          });
      });
    } catch (error) {
      throw error;
    }
  };

  const submitAssignmentData = async () => {
    if (!assignmentInfo) return;
    if (demoId) {
      const assignmentData = {
        supporters: selectedRowKeys[demoId] || [],
        demo_url:
          assignmentInfo.demo_type === DEMO_TYPE_OFFLINE
            ? ''
            : selectedRowKeys[`demo_url_${demoId}`]
              ? selectedRowKeys[`demo_url_${demoId}`][0]
              : assignmentInfo?.demo_url
                ? assignmentInfo?.demo_url
                : '',
        fb_deadline:
          assignmentInfo.demo_type === DEMO_TYPE_OFFLINE ? fbDeadline : null,
      };
      try {
        const result = isSendMail
          ? await CourseService.sendMailAssignmentSupporter(
              courseId,
              assignmentData,
              demoId,
            )
          : await CourseService.completeAssignment(
              courseId,
              assignmentData,
              demoId,
            );
        if (result) {
          if (isSendMail) {
            setSelectedRowKeys({});
            setIsSubmit(!isSubmit);
            await showToastMessage(
              'success',
              '',
              txtSendMaiAssignSupporterSuccess,
            );
          } else {
            if (result?.data?.supporter_assigned) {
              if (assignmentInfo?.demo_type === 1) {
                await deleteEvent(false);
              }
              await showToastMessage(
                'success',
                '',
                txtChangeStatusAssignSupporterSuccess,
              );
              navigate(regexRouter(rc(RouteKey.Home).path));
            } else {
              await showToastMessage('error', txtNotHasSupporterOfDemo, '');
            }
          }
        } else {
          await showToastMessage('error', '模擬授業のアサイン', sendDataFalse);
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    } else {
      return;
    }
  };

  return (
    <>
      {isLoading && <Loader isLoading={true} isFullScreen={true} />}
      <div className="max-w-full mb-5">
        <TabLinks className="mb-4" />
        <div className="px-8 py-5 bg-white mb-4">
          <Heading className="text-color-blue-800">
            <span className="mr-3 inline-block align-middle">
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.9375 23H1.3125C1.16332 23 1.02024 22.9407 0.914752 22.8352C0.809263 22.7298 0.75 22.5867 0.75 22.4375V4.8125C0.75 4.66332 0.809263 4.52024 0.914752 4.41475C1.02024 4.30926 1.16332 4.25 1.3125 4.25H3.9375C4.08668 4.25 4.22976 4.30926 4.33525 4.41475C4.44074 4.52024 4.5 4.66332 4.5 4.8125V22.4375C4.5 22.5867 4.44074 22.7298 4.33525 22.8352C4.22976 22.9407 4.08668 23 3.9375 23Z"
                  fill="#234680"
                />
                <path
                  d="M11.25 10.25V7.8125C11.25 7.66332 11.1907 7.52024 11.0852 7.41475C10.9798 7.30926 10.8367 7.25 10.6875 7.25H5.8125C5.66332 7.25 5.52024 7.30926 5.41475 7.41475C5.30926 7.52024 5.25 7.66332 5.25 7.8125V10.25H11.25Z"
                  fill="#234680"
                />
                <path
                  d="M5.25 20V22.4375C5.25 22.5867 5.30926 22.7298 5.41475 22.8352C5.52024 22.9407 5.66332 23 5.8125 23H10.6875C10.8367 23 10.9798 22.9407 11.0852 22.8352C11.1907 22.7298 11.25 22.5867 11.25 22.4375V20H5.25Z"
                  fill="#234680"
                />
                <path d="M11.25 11.75H5.25V18.5H11.25V11.75Z" fill="#234680" />
                <path
                  d="M15.9375 23H12.5625C12.4133 23 12.2702 22.9407 12.1648 22.8352C12.0593 22.7298 12 22.5867 12 22.4375V2.5625C12 2.41332 12.0593 2.27024 12.1648 2.16475C12.2702 2.05926 12.4133 2 12.5625 2H15.9375C16.0867 2 16.2298 2.05926 16.3352 2.16475C16.4407 2.27024 16.5 2.41332 16.5 2.5625V22.4375C16.5 22.5867 16.4407 22.7298 16.3352 22.8352C16.2298 22.9407 16.0867 23 15.9375 23Z"
                  fill="#234680"
                />
                <path
                  d="M17.2969 5.22031L18.7031 22.4623C18.7094 22.538 18.7308 22.6115 18.7662 22.6786C18.8017 22.7457 18.8503 22.805 18.9092 22.8528C18.9681 22.9006 19.036 22.936 19.109 22.9569C19.1819 22.9777 19.2583 22.9836 19.3336 22.9742L22.7161 22.5523C22.8601 22.5343 22.9916 22.4614 23.0831 22.3487C23.1745 22.236 23.2189 22.0923 23.2069 21.9477L21.7969 4.77547C21.7907 4.70088 21.7696 4.62829 21.735 4.56194C21.7004 4.49558 21.6529 4.43681 21.5953 4.38905C21.5376 4.34129 21.4711 4.30551 21.3994 4.28381C21.3278 4.2621 21.2526 4.25491 21.1781 4.26266L17.797 4.61469C17.6511 4.63045 17.5172 4.7026 17.4237 4.81575C17.3303 4.92889 17.2848 5.07407 17.2969 5.22031Z"
                  fill="#234680"
                />
              </svg>
            </span>
            模擬授業のアサイン
          </Heading>
          {(assignmentInfo && (
            <div className="flex gap-4 mt-4">
              <Label label="ID" className="w-[10%]">
                <TextField
                  classNameChild="w-full block"
                  value={assignmentInfo?.id + '' || ''}
                  disabled
                />
              </Label>
              <Label label="ユーザーID" className="w-[20%]">
                <TextField
                  classNameChild="w-full block"
                  value={assignmentInfo?.course?.user?.username || ''}
                  disabled
                />
              </Label>
              <Label label="タイトル" className="w-[40%]">
                <TextField
                  classNameChild="w-full block"
                  value={assignmentInfo?.course?.title || ''}
                  disabled
                />
              </Label>
              <div className="w-[30%]">
                <Label label="ステータス" className="mb-0" />
                <div className="rounded bg-blue-50 min-h-12 px-4 py-2 flex items-center">
                  <Status type="text" variant="primary">
                    {assignmentInfo?.status_display || ''}
                  </Status>
                </div>
              </div>
            </div>
          )) || <Loader isLoading={true} isFullScreen={true}></Loader>}
          {assignmentInfo &&
            assignmentInfo?.demo_type === DEMO_TYPE_OFFLINE && (
              <div className="flex gap-4 mt-7">
                <Label label="FB期限" className="w-[20%]">
                  <DatePicker
                    className="w-full py-[11px]"
                    popupClassName="cpickerdropdown"
                    size="large"
                    defaultValue={
                      (assignmentInfo?.fb_deadline &&
                        dayjs(assignmentInfo?.fb_deadline, dateFormat)) ||
                      null
                    }
                    value={fbDeadline ? dayjs(fbDeadline, dateFormat) : null}
                    format={dateFormat}
                    onChange={onChange}
                  />
                </Label>
                <div className="w-[60%]">
                  <Label label="動画リンク" className="w-[30%]" />
                  <div className="rounded-md bg-color-blue-5 border border-color-blue-100 px-4 py-[11px] flex items-center">
                    <a
                      href={linkUrl || '#'}
                      className="ps-2 min-h-[24px] text-base text-blue-500 underline truncate max-w-full overflow-hidden whitespace-nowrap"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {linkUrl ? linkUrl : ''}
                    </a>
                  </div>
                </div>
                <div className="w-[20%] mt-1" style={{ width: '200px' }}>
                  {getStatusUploadVideo(statusUploadVideo)}
                </div>
              </div>
            )}
        </div>

        <div className="px-8">
          {assignmentInfo &&
            assignmentInfo?.demo_type === DEMO_TYPE_OFFLINE && (
              <div className="border border-color-gray-40 bg-white p-4 rounded">
                <div className="flex items-center justify-between mb-4">
                  <Heading className="text-color-blue-700">
                    サポーター申請一覧
                  </Heading>
                  <div className="flex justify-between">
                    <Button
                      type="button"
                      className="mr-4 inline-block p-2 rounded-md border-none !text-color-blue-700 hover:bg-color-red-200 bg-color-red-100 min-w-[142px] focus:!bg-color-red-200"
                      size="small"
                      onClick={() => setConfirmReject(true)}
                    >
                      差戻
                    </Button>
                    <Button
                      type="button"
                      variant="tertiary"
                      size="small"
                      iconSuffix={faEnvelope}
                      className={`min-w-[315px] mr-4 !text-color-blue-400 bg-white focus:bg-white ${isUploadingYoutube && 'opacity-50'}`}
                      onClick={showModalConfirmSendMail}
                      disabled={
                        !selectedRowKeys[assignmentInfo?.id] ||
                        selectedRowKeys[assignmentInfo?.id]?.length === 0 ||
                        isUploadingYoutube
                      }
                    >
                      {isUploadingYoutube
                        ? 'Youtubeへアップロード中'
                        : 'サポーターへ 依頼メール送信'}
                    </Button>
                    <Button
                      type="button"
                      variant="primary"
                      size="small"
                      className="min-w-[200px]"
                      onClick={showModalConfirmChangeStatus}
                    >
                      模擬授業完了
                    </Button>
                  </div>
                </div>
                <VideoAssignment
                  dataTable={assignmentInfo.supporters}
                  selectedRowKeys={selectedRowKeys}
                  setSelectedRowKeys={setSelectedRowKeys}
                  demoId={assignmentInfo?.id}
                  supporterAccept={supporterAccept}
                  isSPAll={isSPAll}
                />
              </div>
            )}
          {/* <p className="text-color-red-600 font-bold py-4">
            ----------------- S51・オンライタイプのサポーター割り当て画面
            -----------------
          </p> */}
          {assignmentInfo && assignmentInfo?.demo_type === 1 && (
            <div className="border border-color-gray-40 bg-white p-4 rounded">
              <div className="flex items-center justify-between mb-4">
                <Heading className="text-color-blue-700">スケジュール</Heading>
                <div className="flex justify-between">
                  <Button
                    type="button"
                    className="mr-4 inline-block p-2 rounded-md border-none !text-color-blue-700 hover:bg-color-red-200 bg-color-red-100 min-w-[142px] focus:!bg-color-red-200"
                    size="small"
                    onClick={() => setConfirmReject(true)}
                  >
                    差戻
                  </Button>
                  <Button
                    type="button"
                    variant="tertiary"
                    size="small"
                    iconSuffix={faEnvelope}
                    className="min-w-[315px] mr-4 !text-color-blue-400 bg-white focus:bg-white"
                    onClick={showModalConfirmSendMail}
                    disabled={
                      !selectedRowKeys[assignmentInfo?.id] ||
                      selectedRowKeys[assignmentInfo?.id]?.length === 0
                    }
                  >
                    サポーターへ 依頼メール送信
                  </Button>
                  <Button
                    type="button"
                    variant="primary"
                    size="small"
                    className="min-w-[200px]"
                    onClick={showModalConfirmChangeStatus}
                  >
                    模擬授業完了
                  </Button>
                </div>
              </div>
              <OnlineAssignment
                dataTable={assignmentInfo?.supporters}
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
                demoId={assignmentInfo?.id}
                demoStartAt={assignmentInfo?.start_at ?? ''}
                demoUrl={assignmentInfo?.demo_url ?? ''}
                txtErrorUrl={txtErrorUrl}
                supporterAccept={supporterAccept}
                isSPAll={isSPAll}
              />
            </div>
          )}
        </div>
      </div>
      <BasicModal
        title={''}
        open={isModalConfirmOpen}
        onOk={handleOkConfirm}
        onCancel={handleCancelConfirm}
        centered
        width="680px"
        footer={[
          <div className="grid grid-cols-2 gap-4" key={1}>
            <div>
              <Button
                type="button"
                size="small"
                variant="outline-tertiary"
                className="w-full"
                onClick={handleCancelConfirm}
              >
                キャンセル
              </Button>
            </div>
            <div>
              <Button
                type="button"
                size="small"
                variant="primary"
                className="w-full"
                onClick={handleOkConfirm}
              >
                確定
              </Button>
            </div>
          </div>,
        ]}
      >
        <div className="mt-6">
          {`「${assignmentInfo?.course?.title}」${isSendMail ? 'のサポーターへ依頼メールを送信してよろしいですか？' : 'の模擬授業のアサインを完了してよろしいですか？'}`}
        </div>
      </BasicModal>

      <BasicModal
        title="模擬授業の差し戻し"
        open={confirmReject}
        onOk={handleCancel}
        onCancel={handleCancel}
        centered
        width="680px"
        footer={[
          <form onSubmit={handleSubmit(handleReject)}>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <Button
                  type="button"
                  variant="outline-tertiary"
                  className="w-full"
                  onClick={handleCancel}
                >
                  キャンセル
                </Button>
              </div>
              <div>
                <Button
                  type="submit"
                  variant="danger"
                  className="w-full"
                  disabled={!watch('reason')}
                >
                  差戻
                </Button>
              </div>
            </div>
          </form>,
        ]}
      >
        <Label label="不受理のコメント">
          <TextFieldForm
            {...register('reason')}
            type="textarea"
            classNameChild="w-full block h-[72px]"
            placeholder="コメントを入力してください"
          />
        </Label>
      </BasicModal>
    </>
  );
};
export default MockClassAssignment;
