import { BasicModal } from '../../../components/molecules/BasicModal';
import Label from '../../../components/atoms/Label';
import Checkbox from '../../../components/atoms/Checkbox';
import Button from '../../../components/atoms/Button';
import { useForm } from 'react-hook-form';
import { FILTER_USER_STATUS } from '../../../constants';
import { useEffect, useState } from 'react';
import TextFieldForm from '../../../components/atoms/TextFieldForm';
import { useNavigate } from 'react-router-dom';

const SearchAdvanceUser = ({ isModalOpen, handleOk, handleCancel }: any) => {
  const { handleSubmit, register, setValue } = useForm();
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const [userStatus, setUserStatus] = useState<number[]>(
    params.get('roles')?.split(',').map(Number) || [],
  );

  const handleSearchUser = (formData: any) => {
    const _formData = {
      ...formData,
      search: encodeURIComponent(formData.search?.trim()),
      roles: userStatus.length === 0 ? '' : userStatus,
    };
    let qs = Object.keys(_formData)
      .map(key => {
        if (_formData[key]) {
          return key + '=' + _formData[key];
        } else {
          return '';
        }
      })
      .filter(o => o)
      .join('&');

    navigate(`?${qs}`);
  };

  const handleCheckboxCSChange = (
    rowId: number,
    state: number[],
    setState: any,
  ) => {
    const index = state.indexOf(rowId);
    if (index === -1) {
      setState([...state, rowId]);
    } else {
      const newSelectedRows = [...state];
      newSelectedRows.splice(index, 1);
      setState(newSelectedRows);
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      setValue('search', params?.get('search') || '');
      setUserStatus(params.get('roles')?.split(',').map(Number) || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  return (
    <BasicModal
      title="詳細検索"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      centered
      width="680px"
      footer={[
        <form onSubmit={handleSubmit(handleSearchUser)} id="search_form">
          <div className="grid">
            <Button
              type="submit"
              variant="primary"
              size="medium"
              className="w-full"
              onClick={handleOk}
            >
              検索
            </Button>
          </div>
        </form>,
      ]}
    >
      <form id="search_form">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <Label label="ユーザーID" className="mb-5">
              <TextFieldForm
                type="text"
                placeholder="ユーザーID"
                classNameChild="w-full"
                {...register('search')}
                defaultValue={params.get('search')}
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                  }
                }}
              />
            </Label>
          </div>
        </div>
        <div>
          <div className="text-base font-semibold text-color-gray-06">権限</div>
          <div className="grid grid-cols-3 gap-y-3 gap-x-3">
            {FILTER_USER_STATUS?.map((item, index) => (
              <Checkbox
                id={`role_${index}`}
                label={item}
                onChange={() => {
                  handleCheckboxCSChange(index, userStatus, setUserStatus);
                }}
                checked={userStatus?.includes(index)}
                userForm={true}
              />
            ))}
          </div>
        </div>
      </form>
    </BasicModal>
  );
};
export default SearchAdvanceUser;
