import { Controller, useForm } from "react-hook-form";
import Button from "../../../components/atoms/Button";
import Label from "../../../components/atoms/Label";
import { BasicModal } from "../../../components/molecules/BasicModal";
import TextField from "../../../components/atoms/TextField";
import ContactService from "../../../services/Contact";
import { useEffect, useState } from "react";
import { showToastMessage } from "../../../utils/common";
import { systemError, titleLength, titleRequired, txtCreateContactError, txtCreateContactSuccess, txtEditContactError, txtEditContactSuccess, txtValidateRequiredTitleContact, txtValidateRequiredUrlContact } from "../../../constants/message";
import { IContact } from "../../../constants/types";

const ContactModalRequest = ({ isOpenModal, setIsOpenModal, reloadData, selectedData, setSelectedData }: any) => {
    const { control, handleSubmit, watch, reset, formState: { errors } } = useForm<IContact>();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleCloseModal = () =>{
        setIsOpenModal(false);
        setSelectedData(null);
    }

    const handleSubmitContact = async () => {
        setIsSubmitting(true);
        const formData = watch();
        try {
            if (selectedData) {
                await ContactService.updateContact(selectedData.id, formData).then(
                    response => {
                        if (response?.status === 200) {
                            showToastMessage('success', '', txtEditContactSuccess);
                            setIsOpenModal(false);
                            reloadData();
                        } else {
                            showToastMessage('error', '', txtEditContactError);
                        }
                    },
                )
            } else {
                await ContactService.createContact(formData).then(
                    response => {
                        if (response?.status === 201) {
                            showToastMessage('success', '', txtCreateContactSuccess);
                            setIsOpenModal(false);
                            reloadData();
                        } else {
                            showToastMessage('error', '', txtCreateContactError);
                        }
                    },
                )
            }
        } catch (error) {
            showToastMessage('error', '', systemError);
        } finally {
            setIsSubmitting(false);
        }
    }

    useEffect(() => {
        if (selectedData) {
            const { title, url } = selectedData;
            reset({
                title,
                url
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <BasicModal
                title={selectedData ? '詳細編集' : '新規作成'}
                open={isOpenModal}
                onOk={handleSubmitContact}
                onCancel={handleCloseModal}
                centered
                width="680px"
                footer={[
                    <form onSubmit={handleSubmit(handleSubmitContact)}>
                        <div className="grid">
                            <Button
                                type="submit"
                                variant="primary"
                                size="medium"
                                className="w-full"
                                disabled={isSubmitting}
                            >
                                {selectedData ? '編集 ' : '作成'}
                            </Button>
                        </div>
                    </form>

                ]}
            >
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <Label
                            className="mb-5"
                            label="フォーム名"
                            required={true}
                            textTooltip="フォーム名"
                            textNote={errors?.title?.message}
                            onerror={errors?.title ? true : false}
                        >
                            <Controller
                                control={control}
                                name={`title`}
                                rules={{
                                    required: txtValidateRequiredTitleContact,
                                    maxLength: {
                                        value: 255,
                                        message: titleLength,
                                    },
                                    validate: value => value.trim() !== '' || titleRequired
                                }}
                                render={({ field }) => {
                                    const { onChange, value } = field;
                                    return (
                                        <TextField
                                            classNameChild="w-full block"
                                            value={value}
                                            onChange={onChange}
                                            onerror={errors?.title ? true : false}
                                            id={`title`}
                                        />
                                    );
                                }}
                            />
                        </Label>
                    </div>
                </div>
                <div className="grid gap-4">
                    <div>
                        <Label
                            className="mb-5"
                            label="フォームURL"
                            required={true}
                            textTooltip="フォームURL"
                            textNote={errors?.url?.message}
                            onerror={errors?.url ? true : false}
                        >
                            <Controller
                                control={control}
                                name={`url`}
                                rules={{
                                    required: txtValidateRequiredUrlContact,
                                    validate: value => value.trim() !== '' || titleRequired
                                }}
                                render={({ field }) => {
                                    const { onChange, value } = field;
                                    return (
                                        <TextField
                                            classNameChild="w-full block"
                                            value={value}
                                            onChange={onChange}
                                            onerror={errors?.url ? true : false}
                                            id={`title`}
                                        />
                                    );
                                }}
                            />
                        </Label>
                    </div>
                </div>
            </BasicModal>
        </>
    )
}

export default ContactModalRequest;
