import { TabLinks } from "../../components/templates/TabLinks";
import { Heading } from "../../components/templates/Heading";
import Button from "../../components/atoms/Button";
import { faEnvelope, faSave } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import { AreaForImprvmt, AspectsResponse, CriteriaResponse, IFormFeedback, SuggestionsForImprvmt } from "../../constants/types";
import { useEffect, useState } from "react";
import FeedbackService from "../../services/FeedbackService";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AuthService from "../../services/AuthService";
import { CS_ROLES } from "../../constants";
import { RouteKey, rc } from "../../constants/router";
import { convertToWhiteCircledNumber, isAdmin, isUser, regexRouter, showToastMessage } from "../../utils/common";
import { saveDataErrors, txtSubmitFeedbackSuccessBySupporter, txtAlreadyGotFeedback, txtFeedbackEmail, txtLessThan1000, txtRequiredAreaForImprvmt, txtRequiredFeedbackAspects, txtRequiredFeedbackCriteria, txtRequiredFeedbackOverallScores, txtRequiredFeedbackPositiveAspects, txtTemporaryFeedbackSuccessByAdmin, txtNotHaveSupporterFeedbacked } from "../../constants/message";
import { BasicModal } from "../../components/molecules/BasicModal";
import { Loader } from "../../components/atoms/Loader";
import { getProfile } from "../../helper/localStorage";
import CourseService from "../../services/CourseService";

const FeedbackForm: React.FC = () => {
  const { control, reset, watch, formState: { errors }, trigger, setError, clearErrors } = useForm<IFormFeedback>({});
  const [criteria, setCriteria] = useState<CriteriaResponse | null>(null);
  const [aspects, setAspects] = useState<AspectsResponse | null>(null);
  const [positionSupporter, setPositionSupporter] = useState<number>(0);
  const [role] = useState<number>(AuthService.getUserRole() ?? 0);
  const [viewOnly, setViewOnly] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const overall_scores = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const { courseId, demoId } = useParams<{
    courseId: string;
    demoId: string;
  }>();
  let formData = watch();
  const location = useLocation();
  const ImproveValueDefault = Array.from({ length: 5 }, (_, i) => ({ id: i + 1, value: "" }));
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const dataUser = getProfile();
  const parseData = dataUser ? JSON.parse(dataUser) : null;

  const isAdminPreview = () => {
    return location.pathname.includes(rc(RouteKey.AdminPreviewFormFeedback).main) && role === CS_ROLES.admin;
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      if (parseData) {
        if (!isAdminPreview) {
          navigate('/not-found');
        }
        switch (role) {
          case CS_ROLES.admin:
            await fetchDataByAdminAndUser(parseData);
            break;
          case CS_ROLES.supporter:
            await fetchDataSupporter(parseData);
            break;
          case CS_ROLES.user:
            await fetchDataByAdminAndUser(parseData);
            break;
        }
      }
      const criteriaData = await FeedbackService.getCriteria();
      const aspectsData = await FeedbackService.getAspects();
      setCriteria(criteriaData);
      setAspects(aspectsData);
      setIsLoading(false);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, isSubmit])

  const fetchDataByAdminAndUser = async (user: any) => {
    try {
      const feedbackDataByAdmin = await FeedbackService.getFeedbackInfoByAdmin(
        courseId || '',
        demoId || '',
      );
      const isAdminSendMail = feedbackDataByAdmin?.demo.status === 4;
      if (isAdminSendMail) {
        setViewOnly(true);
        if (role !== CS_ROLES.admin && user?.email !== feedbackDataByAdmin?.course?.user?.email) {
          navigate(regexRouter(rc(RouteKey.Home).path));
        }
      } else {
        setViewOnly(false);
      }
      let dataByAdmin = {
        feedback: feedbackDataByAdmin?.data?.map((feedback: any) => ({
          ...feedback,
          positive_aspects: feedback?.positive_aspects,
          area_for_imprvmt: feedback?.area_for_imprvmt?.length === 0
            ? ImproveValueDefault
            : feedback?.area_for_imprvmt?.map((afi: any) => ({
              id: afi.id,
              value: afi.value ?? ''
            })),
          suggestions_for_imprvmt: feedback?.suggestions_for_imprvmt?.length === 0
            ? ImproveValueDefault
            : feedback?.suggestions_for_imprvmt?.map((sfi: any) => ({
              id: sfi.id,
              value: sfi.value ?? ''
            })),
          criteria: feedback?.criteria?.map((cri: any) => ({
            id: cri.id,
            rate: cri.rate,
            name: cri.name ?? ''
          })),
          aspects: feedback?.aspects?.map((asp: any) => ({
            id: asp.id,
            rate: asp.rate,
            name: asp.name ?? ''
          })),
          overall_scores: feedback?.overall_scores,
          edit_positive_aspect: true,
          edit_improve: true,
          is_submitted: feedback?.is_submitted
        })),
        course: feedbackDataByAdmin?.course,
        demo: feedbackDataByAdmin?.demo,
      }
      reset(dataByAdmin)
      if (isAdminPreview()) {
        setViewOnly(true);
      }
    } catch (error) {
      await showToastMessage('error', txtAlreadyGotFeedback, '');
      navigate(regexRouter(rc(RouteKey.Home).path))
    }
  }

  const fetchDataSupporter = async (user: any) => {
    let isCourseComplete = false;
    try {
      await CourseService.checkCourseComplete(courseId || '').then(response => {
        if (response.status === 200) {
          isCourseComplete = response?.data?.is_completed
        }
      });
      if (isCourseComplete) {
        await fetchDataByAdminAndUser(user);
      } else {
        const feedbackData = await FeedbackService.getFeedbackInfo(
          courseId || '',
          demoId || '',
        );
        if (feedbackData) {
          if (feedbackData?.demo.status === 4) {
            setViewOnly(true);
          } else {
            setViewOnly(false);
          }
          if (feedbackData?.area_for_imprvmt?.length === 0) {
            feedbackData.area_for_imprvmt = ImproveValueDefault;
            feedbackData.suggestions_for_imprvmt = ImproveValueDefault;
          }
          let data = {
            feedback: [
              {
                positive_aspects: feedbackData?.positive_aspects ?? '',
                area_for_imprvmt: feedbackData?.area_for_imprvmt?.map((afi: any) => ({
                  id: afi?.id,
                  value: afi?.value
                })),
                suggestions_for_imprvmt: feedbackData?.suggestions_for_imprvmt?.map((sfi: any) => ({
                  id: sfi?.id,
                  value: sfi?.value
                })),
                criteria: feedbackData?.criteria?.map((cri: any) => ({
                  id: cri.id,
                  rate: null,
                  name: cri.name ?? ''
                })),
                aspects: feedbackData?.aspects?.map((asp: any) => ({
                  id: asp.id,
                  rate: null,
                  name: asp.name ?? ''
                })),
                overall_scores: null,
                edit_positive_aspect: false,
                edit_improve: false,
                created_by: feedbackData?.created_by,
                is_submitted: false
              }
            ],
            course: feedbackData?.course,
            demo: feedbackData?.demo,
          }
          reset(data)
        } else {
          await showToastMessage('error', '', saveDataErrors);
        }
      }
    } catch (error) {
      await showToastMessage('error', txtAlreadyGotFeedback, '');
      navigate(regexRouter(rc(RouteKey.Home).path))
    }
  }

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getTextError = (errors: any, field: string) => {
    if (errors?.length > 0) {
      const positon = errors.findIndex((error: any) => error !== undefined);
      return errors?.[positon]?.[field]?.message
    }
  }

  const setPositionSupporterError = (errors: any) => {
    const positionFeedback = errors.findIndex((error: any) => error !== undefined);
    setPositionSupporter(positionFeedback);
    let element: HTMLElement | null = null;
    // Helper function to get the element by ref name
    const getElementByRef = (ref: { name: string } | undefined) => {
      return ref ? document.getElementById(ref.name) : null;
    };

    let idElementScroll: string | null = null;

    const errorKeys = [
      'criteria',
      'aspects',
      'overall_scores',
      'positive_aspects',
      'area_for_imprvmt',
      'suggestions_for_imprvmt'
    ]
    const foundKey = errorKeys.find(key => errors[positionFeedback]?.[key]);
    if (foundKey) {
      idElementScroll = foundKey === 'area_for_imprvmt' || foundKey === 'suggestions_for_imprvmt'
        ? `feedback.${positionFeedback}.improve`
        : `feedback.${positionFeedback}.${foundKey}`;
    }
    // Check top-level errors
    element = getElementByRef(idElementScroll ? { name: idElementScroll } : undefined)

    if (element instanceof HTMLElement) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center"
      });
    }
  };

  const handleShowTextArea = (index: number, isEditImprove: boolean) => {
    if (formData && formData.feedback && formData.feedback[index]) {
      if (isEditImprove) {
        formData.feedback[index].edit_improve = !formData.feedback[index].edit_improve
      } else {
        formData.feedback[index].edit_positive_aspect = !formData.feedback[index].edit_positive_aspect
      }
    }
    reset(formData, {
      keepErrors: true,
      keepDirty: true,
      keepIsSubmitted: false,
      keepTouched: false,
      keepIsValid: false,
      keepSubmitCount: false,
    })
  };

  const showNotification = async (response: any, isSendFeedback = false) => {
    if (response) {
      if (response.status === 200 || response.status === 201) {
        const txtTitle = isSendFeedback ? txtFeedbackEmail : '';
        const txtContent = isSendFeedback ? "" : (role === CS_ROLES.admin ? txtTemporaryFeedbackSuccessByAdmin : txtSubmitFeedbackSuccessBySupporter);
        if (role === CS_ROLES.supporter) {
          await showToastMessage('success', txtTitle, txtContent);
          navigate(regexRouter(rc(RouteKey.Home).path));
        } else {
          await showToastMessage('success', txtTitle, txtContent);
        }
        setIsSubmit(!isSubmit);
      } else {
        await showToastMessage('error', '', saveDataErrors);
      }
    } else {
      await showToastMessage('error', '', saveDataErrors);
    }
  }
  const appendImprove = () => {
    if (viewOnly) {
      return;
    } else {
      if (formData && formData.feedback && formData.feedback[positionSupporter]) {
        const id = formData.feedback[positionSupporter].area_for_imprvmt.length + 1;
        formData.feedback[positionSupporter].area_for_imprvmt = [
          ...formData.feedback[positionSupporter].area_for_imprvmt,
          {
            id: id,
            value: ""
          }
        ];
        formData.feedback[positionSupporter].suggestions_for_imprvmt = [
          ...formData.feedback[positionSupporter].suggestions_for_imprvmt,
          {
            id: id,
            value: ""
          }
        ];
      }
      reset(formData, {
        keepErrors: true,
        keepDirty: true,
        keepIsSubmitted: false,
        keepTouched: false,
        keepIsValid: false,
        keepSubmitCount: false,
      })
    }
  }
  const filterValueFormSubmit = (formSubmitData: any) => {
    formSubmitData?.feedback?.forEach((feedb: any, ind: number) => {
      let result: number[] = [];
      feedb?.area_for_imprvmt.forEach((item: AreaForImprvmt) => {
        if (item.value !== "") {
          result.push(item.id);
        }
      });
      feedb?.suggestions_for_imprvmt.forEach((item: SuggestionsForImprvmt) => {
        if (item.value !== "") {
          if (!result.includes(item.id)) {
            result.push(item.id);
          }
        }
      });
      formSubmitData.feedback[ind].area_for_imprvmt = feedb?.area_for_imprvmt.filter((item: AreaForImprvmt) => result.includes(item.id));
      formSubmitData.feedback[ind].suggestions_for_imprvmt = feedb?.suggestions_for_imprvmt.filter((item: SuggestionsForImprvmt) => result.includes(item.id));
    });
    return formSubmitData;
  }

  const submitByAdmin = async (isSendFeedback: boolean) => {
    setIsLoading(true);
    let formSubmitData = { ...formData }
    if (isSendFeedback) {
      formSubmitData = {
        ...formSubmitData,
        feedback: formSubmitData?.feedback?.filter(item => item.is_submitted)
      };
      formSubmitData = filterValueFormSubmit(formSubmitData);
    }
    try {
      await FeedbackService.saveFeedbackByAdmin(courseId || '', demoId || '', {
        ...formSubmitData,
        send_feedback: isSendFeedback,
      }).then((response) => {
        if (isSendFeedback) {
          setViewOnly(true);
        }
        reset(formSubmitData,
          {
            keepErrors: false,
            keepDirty: false,
            keepIsSubmitted: false,
            keepTouched: false,
            keepIsValid: false,
            keepSubmitCount: false,
          }
        )
        showNotification(response, isSendFeedback);
      });
    } catch (error) {
    }
    setIsLoading(false);
  }

  const submitBySupporter = async (isSendFeedback: boolean) => {
    setIsLoading(true);
    let formSubmitData = { ...formData }
    type FeedbackKeys = 'created_by' | 'is_submitted';
    const fieldNotSubmit: FeedbackKeys[] = ['created_by', 'is_submitted'];
    fieldNotSubmit.forEach((prop) => {
      if (formSubmitData?.feedback[positionSupporter]?.hasOwnProperty(prop)) {
        delete formSubmitData?.feedback[positionSupporter]?.[prop];
      }
    });
    // filter value of area_for_imprvmt and suggestions_for_imprvmt
    formSubmitData = filterValueFormSubmit(formSubmitData);
    try {
      await FeedbackService.saveFeedback(courseId || '', demoId || '', {
        ...formSubmitData,
        send_feedback: isSendFeedback,
      }).then((response) => {
        showNotification(response);
      });
    } catch (error) {
    }
    setIsLoading(false);
  }

  const customeValidateData = async () => {
    formData?.feedback?.forEach((data: any, index: number) => {
      const hasValidValue = data?.area_for_imprvmt.some((item: any) => item.value !== "" && item.value.length <= 1000);
      const allInvalidAreaValues = data?.area_for_imprvmt.every((item: any) => item.value.length <= 1000);
      // field area_for_imprvmt :1 field have value and max length <= 1000
      if (hasValidValue) {
        // check all field area_for_imprvmt have max length <= 1000
        if (allInvalidAreaValues) {
          clearErrors(`feedback.${index}.area_for_imprvmt`);
        } else {
          setError(`feedback.${index}.area_for_imprvmt`, {
            message: txtLessThan1000,
          })
        }
      } else {
        setError(`feedback.${index}.area_for_imprvmt`, {
          message: txtRequiredAreaForImprvmt,
        })
      }
    });
  };

  const onSubmit = async (isValidate: boolean, isSendFeedback: boolean) => {
    if (!courseId || !demoId || role === CS_ROLES.user) {
      return;
    }
    if (role === CS_ROLES.admin) {
      // 1 feedback complete -> admin can submit , not need validate
      const hasSubmitted = formData?.feedback?.some(item => item.is_submitted);
      if (hasSubmitted) {
        submitByAdmin(isSendFeedback);
        setIsModalOpen(false);
      } else {
        if (isValidate) {
          showToastMessage('error', '', txtNotHaveSupporterFeedbacked);
        } else {
          submitByAdmin(isSendFeedback)
        }
        setIsModalOpen(false);
      }
    } else {
      await customeValidateData();
      const res = await trigger();
      if (res && Object.keys(errors).length === 0) {
        submitBySupporter(isSendFeedback);
      } else {
        setPositionSupporterError(errors?.feedback)
      }
    }
  };

  const getSmallCircleCheck = (isChecked: boolean) => {
    return (
      <div className={`flex items-center justify-center ${isChecked ? '' : 'opacity-50'}`}>
        <label
          htmlFor="radio"
          className={`w-3 h-3 rounded-full border border-gray-600 flex items-center justify-center transition-colors bg-white`}
        >
          <div className={`w-2 h-2  rounded-full align-middle ${isChecked ? 'bg-gray-600' : 'bg-gray-200'}`}></div>
        </label>
      </div>
    )
  }

  return (
    <>
      <Loader isLoading={isLoading} isFullScreen={false}>
        <div className="max-w-full">
          <TabLinks className="mb-4" />
          <form>
            <div className="px-8 py-5">
              <div className="border border-color-gray-40 rounded bg-white p-4">
                <div className="flex items-center justify-between mb-4">
                  <Heading className="text-color-blue-700">
                    フィードバックフォーム
                  </Heading>
                  <div className="flex gap-4 items-center">
                    {role === CS_ROLES.admin && (
                      <>
                        <Button
                          type="button"
                          variant="outline-primary"
                          size="small"
                          iconSuffix={faSave}
                          onClick={() => {
                            onSubmit(false, false);
                          }}
                          disabled={viewOnly}
                        >
                          一時保存
                        </Button>
                        <Button
                          type="button"
                          variant="primary"
                          size="small"
                          iconSuffix={faEnvelope}
                          onClick={() => setIsModalOpen(true)}
                          disabled={viewOnly}
                        >
                          結果を電子メールで通知する
                        </Button>
                      </>
                    )}
                    {role === CS_ROLES.supporter && (
                      <Button
                        type="button"
                        variant="primary"
                        size="small"
                        iconSuffix={faSave}
                        onClick={() => {
                          onSubmit(true, false);
                        }}
                        disabled={viewOnly}
                      >
                        フィードバックを提出
                      </Button>
                    )}
                  </div>
                </div>

                {(role === CS_ROLES.admin || viewOnly) && (
                  <div className="flex gap-x-8 border-b border-color-gray-02 xl:min-w-[448px] mb-6">
                    {formData?.feedback?.map((feedb: any, indSupporter: number) => (
                      <>
                        <button
                          type="button"
                          className=
                          {
                            positionSupporter === indSupporter ?
                              "text-base pt-[5px] pb-1 px-1 border-x-0 border-t-0 border-b-2 inline-block text-start min-w-20 text-color-blue-400 border-color-blue-400 font-semibold hover:text-color-blue-400 relative -bottom-[1px]"
                              :
                              "text-base pt-[5px] pb-1 px-1 border-x-0 border-t-0 border-b-2 inline-block text-start min-w-20 text-color-gray-200 border-transparent hover:text-color-blue-400 relative -bottom-[1px]"
                          }
                          onClick={() => {
                            setPositionSupporter(indSupporter);
                          }}
                        >
                          {
                            (role == CS_ROLES.supporter && feedb?.created_by?.id === parseData?.id) || role == CS_ROLES.admin
                              ? feedb?.created_by?.username
                              : `Supporter${formData?.feedback?.length > 1 ? indSupporter + 1 : ''}`
                          }
                          {
                            feedb?.is_submitted
                              ?
                              (
                                <>
                                  <span className='text-color-green-600 font-semibold ml-1'>(FB済)</span>
                                </>
                              )
                              :
                              (
                                <>
                                  <span className='text-color-red-500 font-semibold ml-1'>(FB未)</span>
                                </>
                              )
                          }

                        </button>
                      </>
                    ))}
                  </div>
                )}

                <div className="mb-4 border border-color-blue-100 flex gap-4">
                  <div className="w-1/3 bg-color-blue-50 p-7 flex">
                    <div className="mr-8 pr-8 border-r border-r-color-gray-40">
                      <div className="font-semibold text-xl text-color-blue-500">
                        模擬授業フィードバック
                      </div>
                      <div className="text-color-gray-200 font-medium">
                        {
                          (role == CS_ROLES.supporter && formData?.feedback?.[positionSupporter]?.created_by?.id === parseData?.id) || role == CS_ROLES.admin
                            ? formData?.feedback?.[positionSupporter]?.created_by?.username
                            : `Supporter${formData?.feedback?.length > 1 ? positionSupporter + 1 : ''}`
                        }
                      </div>
                    </div>
                    <div>
                      <div className="text-color-gray-200 font-medium">
                        {dayjs(formData?.demo?.end_at)?.format('YYYY年MM月DD日')} 実施
                      </div>
                      <div className="font-semibold text-xl text-color-gray-600">
                        {formData?.course?.user?.username} 様
                      </div>
                    </div>
                  </div>
                  <div className="w-2/3 flex items-center justify-between">
                    <div className="font-semibold text-xl text-color-blue-500">
                      {formData?.course?.title}
                    </div>
                    <div className="mr-14 border px-8 py-4 border-color-blue-600 font-bold text-2xl">
                      {formData?.feedback && formData?.feedback[positionSupporter]?.is_submitted ? 'FB済' : 'FB未'}
                    </div>
                  </div>
                </div>
                {errors && errors?.feedback &&
                  errors?.feedback?.[positionSupporter]?.criteria
                  && (
                    <div className="text-red-500">
                      {
                        getTextError(errors?.feedback?.[positionSupporter]?.criteria, 'rate')
                      }
                    </div>
                  )}
                {formData?.feedback?.map((feedb: any, index: number) => (
                  <div key={index} className={`${positionSupporter === index ? `` : `hidden`}`}>
                    <table className="border border-color-blue-100 border-collapse w-full text-xs mb-4">
                      <thead>
                        <tr className="text-center">
                          <th className="align-middle border border-color-blue-100 bg-color-blue-50 font-semibold text-color-gray-600 py-2 px-3 w-[68px]">
                            ID
                          </th>
                          <th className="align-middle border border-color-blue-100 bg-color-blue-50 font-semibold text-color-gray-600 py-2 px-3 text-left w-[300px]">
                            この授業について
                          </th>
                          {criteria?.rate_choices?.map(rate_choice => (
                            <th
                              key={rate_choice.value}
                              className="align-top border border-color-blue-100 bg-color-blue-50 font-semibold text-color-blue-800 py-2 px-3 w-[168px] h-[58px]"
                            >
                              {rate_choice.name}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {feedb?.criteria?.map((f: any, idx: number) => (
                          <tr key={f.id} id={`feedback.${index}.criteria`}>
                            <td className="border border-color-blue-100 text-color-gray-400 py-2 px-3 text-center">
                              {f.id}
                            </td>
                            <td className="border border-color-blue-100 text-color-blue-900 py-2 px-3">
                              {f.name}
                            </td>
                            {criteria?.rate_choices?.map((cri, index2) => (
                              <td
                                key={cri.value}
                                className="border border-color-blue-100 font-semibold text-color-blue-800 py-2 px-3 text-center"
                              >
                                <Controller
                                  control={control}
                                  name={`feedback.${index}.criteria.${idx}.rate`}
                                  rules={{
                                    required: txtRequiredFeedbackCriteria,
                                  }}
                                  render={({ field }) => {
                                    const { onChange, value } = field;
                                    const isChecked = typeof value === 'number' ? (cri.value === Number(value) ? true : false) : false;
                                    if (viewOnly) {
                                      return (
                                        getSmallCircleCheck(isChecked)
                                      )
                                    } else {
                                      return (
                                        <div className="flex items-center justify-center">
                                          <input
                                            type="radio"
                                            value={cri.value}
                                            onChange={(e) => onChange(Number(e.target.value))}
                                            name={`feedback.${index}.criteria.${idx}.rate`}
                                            defaultChecked={isChecked}
                                            disabled={viewOnly}
                                            className="align-middle"
                                          />
                                        </div>
                                      );
                                    }
                                  }}
                                />
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    {errors && errors?.feedback && errors?.feedback?.[positionSupporter]?.aspects
                      && (
                        <div className="text-red-500">
                          {
                            getTextError(errors?.feedback?.[positionSupporter]?.aspects, 'rate')
                          }
                        </div>
                      )}
                    <table className="border border-color-blue-100 border-collapse w-full text-xs mb-4">
                      <thead>
                        <tr className="text-center">
                          <th className="align-middle border border-color-blue-100 bg-color-blue-50 font-semibold text-color-gray-600 py-2 px-3 w-[68px]">
                            ID
                          </th>
                          <th className="align-middle border border-color-blue-100 bg-color-blue-50 font-semibold text-color-gray-600 py-2 px-3 text-left w-[300px]">
                            授業者の伝え方（デリバリーなど）
                          </th>
                          {aspects?.rate_choices?.map(rate_choice => (
                            <th
                              key={rate_choice.value}
                              className="align-middle border border-color-blue-100 bg-color-blue-50 font-semibold text-color-blue-800 py-2 px-3 w-[168px] h-[58px]"
                            >
                              {rate_choice.name}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {feedb?.aspects?.map((fb: any, ind: any) => (
                          <tr key={fb.id} id={`feedback.${index}.aspects`}>
                            <td className="border border-color-blue-100 text-color-gray-400 py-2 px-3 text-center">
                              {fb.id}
                            </td>
                            <td className="border border-color-blue-100 text-color-blue-900 py-2 px-3">
                              {fb.name}
                            </td>
                            {aspects?.rate_choices?.map((asp, index2) => (
                              <td
                                key={asp.value}
                                className="border border-color-blue-100 font-semibold text-color-blue-800 px-3 text-center"
                              >
                                <Controller
                                  control={control}
                                  name={`feedback.${index}.aspects.${ind}.rate`}
                                  rules={{
                                    required: txtRequiredFeedbackAspects,
                                  }}
                                  render={({ field }) => {
                                    const { onChange, value } = field;
                                    const isChecked = typeof value === 'number' ? (asp.value === Number(value) ? true : false) : false;
                                    if (viewOnly) {
                                      return (
                                        getSmallCircleCheck(isChecked)
                                      )
                                    } else {
                                      return (
                                        <input
                                          type="radio"
                                          value={asp.value}
                                          onChange={(e) => onChange(Number(e.target.value))}
                                          name={`feedback.${index}.aspects.${ind}.rate`}
                                          defaultChecked={isChecked}
                                          disabled={viewOnly}
                                          className="align-middle"
                                        />
                                      );
                                    }
                                  }}
                                />
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    {errors && errors?.feedback && errors?.feedback?.[positionSupporter]?.overall_scores
                      && (
                        <div className="text-red-500">
                          {
                            errors?.feedback?.[positionSupporter]?.overall_scores?.message
                          }
                        </div>
                      )}
                    <table className="border border-color-blue-100 border-collapse w-full text-xs mb-4">
                      <thead>
                        <tr className="text-left">
                          <th className="align-middle border border-color-blue-100 bg-color-blue-50 font-semibold text-color-blue-800 py-2 px-3 h-[58px]" colSpan={12}>
                            授業全体として10段階で評価をお願いします
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr id={`feedback.${index}.overall_scores`}>
                          <td className="border border-color-blue-100 py-2 px-10 text-center h-[48px]"></td>
                          {overall_scores.map((value) => (
                            <td className="border border-color-blue-100 bg-color-blue-50 text-color-blue-800 font-semibold py-2 px-10 text-right w-[61px] h-[48px]" key={`label-${value}`}>
                              {value}
                            </td>
                          ))}
                          <td className="border border-color-blue-100 py-2 px-10 text-center h-[48px]"></td>
                        </tr>
                        <tr>
                          <td className="border border-color-blue-100 py-2 px-10 text-center text-color-blue-800 font-semibold">
                            全く良くなかった
                          </td>
                          {overall_scores.map((valueRadio) => (
                            <td className="border border-color-blue-100 text-color-blue-800 font-semibold py-2 px-10 text-right w-[61px]" key={`radio-${valueRadio}`}>
                              <Controller
                                control={control}
                                name={`feedback.${index}.overall_scores`}
                                rules={{
                                  required: txtRequiredFeedbackOverallScores,
                                }}
                                render={({ field }) => {
                                  const { onChange, value } = field;
                                  const isChecked = typeof value === 'number' ? (valueRadio === Number(value) ? true : false) : false;
                                  if (viewOnly) {
                                    return (
                                      getSmallCircleCheck(isChecked)
                                    )
                                  } else {
                                    return (
                                      <input
                                        type="radio"
                                        value={valueRadio}
                                        onChange={(e) => onChange(Number(e.target.value))}
                                        name={`feedback.${index}.overall_scores`}
                                        defaultChecked={typeof value === 'number' ? (valueRadio === Number(value) ? true : false) : false}
                                        disabled={viewOnly}
                                        className="align-middle"
                                      />
                                    );
                                  }
                                }}
                              />
                            </td>
                          ))}
                          <td className="border border-color-blue-100 py-2 px-10 text-center text-color-blue-800 font-semibold">
                            大変良かった
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    {errors && errors?.feedback && errors?.feedback?.[positionSupporter]?.positive_aspects
                      && (
                        <div className="text-red-500">
                          {
                            errors?.feedback?.[positionSupporter]?.positive_aspects?.message
                          }
                        </div>
                      )}
                    <div className="border border-color-blue-100 rounded overflow-hidden min-h-[520px] mb-4">
                      <div className="bg-color-blue-50 border-b border-b-color-blue-100 text-sm font-semibold text-color-blue-800 leading-4 rounded-t p-4">
                        <div className="flex justify-between items-center">
                          <div>
                            良かった点
                          </div>
                          {
                            role === CS_ROLES.admin && (
                              <div>
                                <button
                                  type="button"
                                  onClick={() => handleShowTextArea(index, false)}
                                  className="rounded border-0 p-0 font-normal inline-flex items-center justify-center bg-color-blue-50  w-7 h-7 text-color-blue-300 me-2 text-xl hover:bg-color-blue-50 hover:text-color-blue-500"
                                >
                                  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.3 6.925L12.05 2.725L13.45 1.325C13.8333 0.941667 14.3042 0.75 14.8625 0.75C15.4208 0.75 15.8917 0.941667 16.275 1.325L17.675 2.725C18.0583 3.10833 18.2583 3.57083 18.275 4.1125C18.2917 4.65417 18.1083 5.11667 17.725 5.5L16.3 6.925ZM14.85 8.4L4.25 19H0V14.75L10.6 4.15L14.85 8.4Z" fill="#1C1B1F" />
                                  </svg>
                                </button>
                              </div>
                            )}
                        </div>
                      </div>
                      <div className={`py-2 px-4 text-sm text-color-gray-400 feedback.${index}.positive_aspects`} id={`feedback.${index}.positive_aspects`}>
                        <Controller
                          control={control}
                          name={`feedback.${index}.positive_aspects`}
                          rules={{
                            required: txtRequiredFeedbackPositiveAspects,
                            maxLength: {
                              value: 1000,
                              message: txtLessThan1000
                            },
                          }}
                          render={({ field }) => {
                            const { onChange, value } = field;
                            return (
                              <textarea
                                value={value}
                                onChange={onChange}
                                className="w-full h-full min-h-[444px] focus-visible:outline-offset-2 focus-visible:outline-1 focus:outline-none resize-none"
                                placeholder="良かった点を入力してください"
                                disabled={viewOnly || formData?.feedback[index]?.edit_positive_aspect}
                              ></textarea>
                            );
                          }}
                        />
                      </div>
                    </div>

                    {errors && errors?.feedback &&
                      (errors?.feedback?.[positionSupporter]?.area_for_imprvmt
                        || errors?.feedback?.[positionSupporter]?.suggestions_for_imprvmt)
                      && (
                        <div className="text-red-500">
                          {
                            errors?.feedback?.[positionSupporter]?.area_for_imprvmt?.message ||
                            getTextError(errors?.feedback?.[positionSupporter]?.suggestions_for_imprvmt, 'value') || ''
                          }
                        </div>
                      )}
                    <table className="border border-color-blue-100 border-collapse w-full text-xs mb-4" id={`feedback.${index}.improve`}>
                      <thead>
                        <tr className="text-left">
                          <th className="align-middle border border-color-blue-100 bg-color-blue-50 font-semibold text-color-blue-800 py-2 px-3 h-[58px]" style={{ width: '49%' }} colSpan={2}>
                            改善するとよいと思った点（複数ある場合は箇条書きで）
                          </th>
                          <th className="align-middle border border-color-blue-100 bg-color-blue-50 font-semibold text-color-blue-800 py-2 px-3 h-[58px]" style={{ width: '2%' }}>
                          </th>
                          <th className="align-middle border border-color-blue-100 bg-color-blue-50 font-semibold text-color-blue-800 py-2 px-3 h-[58px]" style={{ width: '49%' }} colSpan={2}>
                            <div className="flex justify-between items-center">
                              <div>
                                改善点に対応する改善案
                              </div>
                              <div>
                                <button
                                  type="button"
                                  onClick={appendImprove}
                                  className="rounded border-0 p-0 font-normal inline-flex items-center justify-center bg-color-blue-50  w-7 h-7 text-color-blue-300 me-2 text-xl hover:bg-color-blue-50 hover:text-color-blue-500"
                                >

                                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9 0.875C4.51992 0.875 0.875 4.51992 0.875 9C0.875 13.4801 4.51992 17.125 9 17.125C13.4801 17.125 17.125 13.4801 17.125 9C17.125 4.51992 13.4801 0.875 9 0.875ZM12.125 9.625H9.625V12.125C9.625 12.2908 9.55915 12.4497 9.44194 12.5669C9.32473 12.6842 9.16576 12.75 9 12.75C8.83424 12.75 8.67527 12.6842 8.55806 12.5669C8.44085 12.4497 8.375 12.2908 8.375 12.125V9.625H5.875C5.70924 9.625 5.55027 9.55915 5.43306 9.44194C5.31585 9.32473 5.25 9.16576 5.25 9C5.25 8.83424 5.31585 8.67527 5.43306 8.55806C5.55027 8.44085 5.70924 8.375 5.875 8.375H8.375V5.875C8.375 5.70924 8.44085 5.55027 8.55806 5.43306C8.67527 5.31585 8.83424 5.25 9 5.25C9.16576 5.25 9.32473 5.31585 9.44194 5.43306C9.55915 5.55027 9.625 5.70924 9.625 5.875V8.375H12.125C12.2908 8.375 12.4497 8.44085 12.5669 8.55806C12.6842 8.67527 12.75 8.83424 12.75 9C12.75 9.16576 12.6842 9.32473 12.5669 9.44194C12.4497 9.55915 12.2908 9.625 12.125 9.625Z" fill="#1F1F1F" />
                                  </svg>
                                </button>
                                {
                                  role === CS_ROLES.admin && (
                                    <button
                                      type="button"
                                      onClick={(e) => {
                                        handleShowTextArea(index, true)
                                      }}
                                      className="rounded border-0 p-0 font-normal inline-flex items-center justify-center bg-color-blue-50  w-7 h-7 text-color-blue-300 me-2 text-xl hover:bg-color-blue-50 hover:text-color-blue-500"
                                    >
                                      <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.3 6.925L12.05 2.725L13.45 1.325C13.8333 0.941667 14.3042 0.75 14.8625 0.75C15.4208 0.75 15.8917 0.941667 16.275 1.325L17.675 2.725C18.0583 3.10833 18.2583 3.57083 18.275 4.1125C18.2917 4.65417 18.1083 5.11667 17.725 5.5L16.3 6.925ZM14.85 8.4L4.25 19H0V14.75L10.6 4.15L14.85 8.4Z" fill="#1C1B1F" />
                                      </svg>
                                    </button>
                                  )
                                }
                              </div>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {feedb?.area_for_imprvmt?.map((afi: any, ind: number) => (
                          <tr>
                            <td className="border border-color-blue-100 py-4 px-0 text-center h-[12px]">
                              {convertToWhiteCircledNumber(ind + 1)}
                            </td>
                            <td className="border border-color-blue-100 py-4 px-2 text-center h-[48px]">
                              <Controller
                                control={control}
                                name={`feedback.${index}.area_for_imprvmt.${ind}.value`}
                                rules={{
                                  // required: txtRequiredAreaForImprvmt,
                                  // maxLength: {
                                  //   value: 1000,
                                  //   message: txtLessThan1000
                                  // },
                                }}
                                render={({ field }) => {
                                  const { onChange, value } = field;
                                  return (
                                    <textarea
                                      value={value}
                                      onChange={onChange}
                                      className="w-full h-full min-h-[50px] focus-visible:outline-offset-2 focus-visible:outline-1 focus:outline-none resize-none"
                                      disabled={viewOnly || formData?.feedback[index]?.edit_improve}
                                      placeholder="改善すると良いと思った点を入力してください"
                                    ></textarea>
                                  );
                                }}
                              />
                            </td>
                            <td className="border border-color-blue-100 py-2 px-4 text-center h-[48px]">
                              <svg width="18" height="7" viewBox="0 0 18 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.296 6.1C13.536 5.588 13.768 5.14 13.992 4.756C14.232 4.372 14.464 4.052 14.688 3.796H0.696V2.788H14.688C14.464 2.516 14.232 2.188 13.992 1.804C13.768 1.42 13.536 0.979999 13.296 0.483999H14.136C15.144 1.652 16.2 2.516 17.304 3.076V3.508C16.2 4.052 15.144 4.916 14.136 6.1H13.296Z" fill="#1A3561" />
                              </svg>
                            </td>
                            <td className="border border-color-blue-100 py-4 px-2 text-center h-[48px]">
                              <Controller
                                control={control}
                                name={`feedback.${index}.suggestions_for_imprvmt.${ind}.value`}
                                rules={{
                                  maxLength: {
                                    value: 1000,
                                    message: txtLessThan1000
                                  },
                                }}
                                render={({ field }) => {
                                  const { onChange, value } = field;
                                  return (
                                    <textarea
                                      value={value}
                                      onChange={onChange}
                                      className="w-full h-full min-h-[50px] focus-visible:outline-offset-2 focus-visible:outline-1 focus:outline-none resize-none"
                                      disabled={viewOnly || formData?.feedback[index]?.edit_improve}
                                      placeholder="改善案"
                                    ></textarea>
                                  );
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ))}
              </div>
            </div>
          </form>
        </div>
        <BasicModal
          title=""
          open={isModalOpen}
          onOk={true}
          onCancel={handleCancel}
          centered
          width="680px"
          footer={[
            <div className="grid grid-cols-2 gap-4">
              <div>
                <Button
                  type="button"
                  variant="outline-tertiary"
                  className="w-full"
                  size="small"
                  onClick={handleCancel}
                >
                  キャンセル
                </Button>
              </div>
              <div>
                <Button
                  type="button"
                  variant="primary"
                  className="w-full"
                  size="small"
                  onClick={() => {
                    onSubmit(true, true);
                  }}
                  disabled={viewOnly}
                >
                  通知する
                </Button>
              </div>
            </div>,
          ]}
        >
          <div className="mt-4">
            {'模擬授業のFB結果をメールで通知してよろしいですか？'}
          </div>
        </BasicModal>
      </Loader>
    </>
  );
};
export default FeedbackForm;
