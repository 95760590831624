import { Controller } from "react-hook-form";
import Label from "../../components/atoms/Label";
import TextField from "../../components/atoms/TextField";
import { goalsLength, goalsRequired, methodLength1000, methodRequired, objectiveLength, objectiveRequired, titleLength, titleRequired } from "../../constants/message";

const InformationLesson = ({ control, errors, isViewOnly, isCollapse, setIsCollapse }: any) => {
    return (
        <>
            <div className="flex-1">
                {/* start section */}
                <div className="border border-color-gray-40 bg-white rounded p-2">
                    <Label
                        className={!isCollapse ? '' : 'mb-2'}
                        label="タイトル"
                        required={!isViewOnly}
                        textTooltip="タイトル"
                        textNote={errors?.title?.message}
                        onerror={errors?.title ? true : false}
                        collapse={true}
                        isCollapse={isCollapse}
                        setIsCollapse={setIsCollapse}
                    >
                        <Controller
                            control={control}
                            name={`title`}
                            rules={{
                                required: titleRequired,
                                maxLength: {
                                    value: 255,
                                    message: titleLength,
                                },
                                validate: value => value.trim() !== '' || titleRequired
                            }}
                            render={({ field }) => {
                                const { onChange, value } = field;
                                return (
                                    <TextField
                                        classNameChild="w-full block"
                                        value={value}
                                        onChange={onChange}
                                        placeholder="タイトル"
                                        onerror={errors?.title ? true : false}
                                        id={`title`}
                                        size='small'
                                        readOnly={isViewOnly}
                                    />
                                );
                            }}
                        />
                    </Label>
                    <Label
                        className={`mb-2 ${isCollapse ? '' : 'hidden'}`}
                        label="目的"
                        required={!isViewOnly}
                        textTooltip="目的"
                        textNote={errors?.objective?.message}
                        onerror={errors?.objective ? true : false}
                    >
                        <Controller
                            control={control}
                            name={`objective`}
                            rules={{
                                required: objectiveRequired,
                                maxLength: {
                                    value: 1000,
                                    message: objectiveLength,
                                },
                                validate: value => value.trim() !== '' || objectiveRequired
                            }}
                            render={({ field }) => {
                                const { onChange, value } = field;
                                return (
                                    <TextField
                                        type="textarea"
                                        classNameChild="w-full block min-h-[90px]"
                                        placeholder="目的"
                                        value={value}
                                        onChange={onChange}
                                        onerror={errors?.objective ? true : false}
                                        id={`objective`}
                                        readOnly={isViewOnly}
                                    />
                                );
                            }}
                        />
                    </Label>
                    <div className={`grid grid-cols-2 gap-x-4 ${isCollapse ? '' : 'hidden'}`}>
                        <div>
                            <Label
                                className="mb-2"
                                label="クラスの達成目標"
                                required={!isViewOnly}
                                textTooltip="クラスの達成目標"
                                textNote={errors?.goal?.message}
                                onerror={errors?.goal ? true : false}
                            >
                                <Controller
                                    control={control}
                                    name={`goal`}
                                    rules={{
                                        required: goalsRequired,
                                        maxLength: {
                                            value: 1000,
                                            message: goalsLength,
                                        },
                                        validate: value => value.trim() !== '' || goalsRequired
                                    }}
                                    render={({ field }) => {
                                        const { onChange, value } = field;
                                        return (
                                            <TextField
                                                type="textarea"
                                                classNameChild="w-full block min-h-[90px]"
                                                value={value}
                                                onChange={onChange}
                                                placeholder="クラスの達成目標"
                                                onerror={errors?.goal ? true : false}
                                                id={`goal`}
                                                readOnly={isViewOnly}
                                            />
                                        );
                                    }}
                                />
                            </Label>
                        </div>
                        <div>
                            <Label
                                className="mb-2"
                                label="目標達成の測定方法"
                                required={!isViewOnly}
                                textTooltip="目標達成の測定方法"
                                textNote={errors?.method?.message}
                                onerror={errors?.method ? true : false}
                            >
                                <Controller
                                    control={control}
                                    name={`method`}
                                    rules={{
                                        required: methodRequired,
                                        maxLength: {
                                            value: 1000,
                                            message: methodLength1000
                                        },
                                        validate: value => value.trim() !== '' || methodRequired
                                    }}
                                    render={({ field }) => {
                                        const { onChange, value } = field;
                                        return (
                                            <TextField
                                                type="textarea"
                                                classNameChild="w-full block min-h-[90px]"
                                                value={value}
                                                onChange={onChange}
                                                placeholder="目標達成の測定方法"
                                                onerror={errors?.method ? true : false}
                                                id={`method`}
                                                readOnly={isViewOnly}
                                            />
                                        );
                                    }}
                                />
                            </Label>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default InformationLesson;