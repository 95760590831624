import mainAxiosInstance from './api';
import {
  getAccessToken,
  getProfile,
  removeAccessToken,
  removeCode,
  removeProfile,
  setAccessToken,
  setPofile,
} from '../helper/localStorage';
import { IRegisterAccount } from '../constants/types';

interface User {
  id: number;
  username: string;
  role: string;
  role_display: string;
}

interface LoginResponse {
  token: string;
  user: User;
}

interface ResetPass {
  status: number;
  message: string;
}

interface checkExpiration {
  token: string;
  expires: string;
}

class AuthService {
  static async login(username: string, password: string): Promise<boolean> {
    try {
      const response = await mainAxiosInstance.post<LoginResponse>(
        'auth/login/',
        {
          username,
          password,
        },
      );

      if (response.status === 200) {
        const token = response.data.token;
        const user = response.data.user;
        if (token && user) {
          setAccessToken(token);
          setPofile(JSON.stringify(user));
          return true;
        }
      }
      return false;
    } catch (error) {
      return false;
    }
  }

  static async logout(): Promise<void> {
    try {
      // Lấy token từ localStorage
      const token = getAccessToken();

      if (!token) {
        throw new Error('Token not found');
      }

      const response = await mainAxiosInstance.post(
        'auth/logout/',
        {},
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        },
      );

      if (response.status === 200) {
        removeAccessToken();
        removeProfile();
        removeCode();
      }
    } catch (error) {
    }
  }

  static async resetPassword(username: string): Promise<ResetPass | boolean> {
    try {
      const response = await mainAxiosInstance.post('auth/reset-pwd-request/', {
        username,
      });

      return {
        status: response.status,
        message: response.data.detail,
      };
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        return {
          status: 400,
          message: error.response.data.detail,
        };
      } else {
        return false;
      }
    }
  }

  static async changePassword(
    new_password: string,
    confirm_password: string,
    token: string,
  ): Promise<number | boolean> {
    try {
      const response = await mainAxiosInstance.post<boolean>(
        'auth/reset-pwd/' + token + '/',
        {
          new_password,
          confirm_password,
        },
      );

      return response.status;
    } catch (error) {
      return false;
    }
  }

  static async checkExpiration(token: string): Promise<number | boolean> {
    try {
      const response = await mainAxiosInstance.get<checkExpiration>(
        'auth/reset-pwd/' + token + '/',
        {},
      );

      return response.status;
    } catch (error) {
      return false;
    }
  }

  static isAuthenticated(): boolean {
    const token = getAccessToken();
    const user = getProfile();
    if (token && user) {
      return true;
    }
    return false;
  }

  static getTokenLogin(): string | null {
    return localStorage.getItem('access_token') || null;
  }

  static getUserLogin(): User | null {
    const user = localStorage.getItem('profile');
    if (user) {
      return JSON.parse(user);
    }
    return null;
  }

  static getUserRole(): number | null {
    const user = this.getUserLogin();
    if (user && typeof user.role === 'number') {
      return parseInt(user.role, 10);
    }
    return null;
  }

  static async registerAccount(formData: IRegisterAccount): Promise<any> {
    try {
      const response = await mainAxiosInstance.post(`users/register/`, {
        ...formData,
      });
      if (response.status === 201) {
        return response.status;
      } else {
        return response.data;
      }
    } catch (error: any) {
      if (error?.response?.status === 400) {
        return Object.keys(error?.response.data)[0];
      }
      return false;
    }
  }
}

export default AuthService;
