import { Controller } from "react-hook-form";
import Label from "../../components/atoms/Label";
import TextField from "../../components/atoms/TextField";

export default function ViewOnlySubSection({ control, isViewOnly, subs, errors, ind  }: any) {
    return (
        <>
            <div>
                {subs?.map((field: any, index: any) => (
                    <div className="relative" key={field.id}>
                        <div>
                            <div className="grid grid-cols-3 gap-x-4">
                                <div>
                                    <Label
                                        label="内容"
                                        textTooltip="内容"
                                        required = {!isViewOnly}
                                        textNote={errors?.sections && errors?.sections[ind]?.subs && errors.sections[ind]?.subs[index]?.content && errors.sections[ind]?.subs[index]?.content?.message}
                                        onerror={errors?.sections && errors?.sections[ind]?.subs && errors.sections[ind]?.subs[index]?.content ? true : false}
                                        isShowLabel={false}
                                    >
                                        <Controller
                                            control={control}
                                            name={`sections[${ind}].subs[${index}].content`}
                                            rules={{
                                                maxLength: {
                                                    value: 2000,
                                                    message: 'Maximum length is 2000 characters'
                                                },
                                            }}
                                            render={({ field }) => {
                                                const { onChange, value } = field;
                                                return (
                                                    <TextField
                                                        type="textarea"
                                                        classNameChild="w-full block min-h-[90px]"
                                                        placeholder="内容"
                                                        value={value}
                                                        onChange={onChange}
                                                        onerror={errors?.sections && errors?.sections[ind]?.subs && errors.sections[ind]?.subs[index]?.content ? true : false}
                                                        id={`sections[${ind}].subs[${index}].content`}
                                                        readOnly={isViewOnly}
                                                    />
                                                );
                                            }}
                                        />
                                    </Label>
                                </div>
                                <div>
                                    <Label
                                        label="方法"
                                        textTooltip="方法"
                                        required = {!isViewOnly}
                                        textNote={errors?.sections && errors?.sections[ind]?.subs && errors.sections[ind]?.subs[index]?.method && errors.sections[ind]?.subs[index]?.method?.message}
                                        onerror={errors?.sections && errors?.sections[ind]?.subs && errors.sections[ind]?.subs[index]?.method ? true : false}
                                        isShowLabel={false}
                                    >
                                        <Controller
                                            control={control}
                                            name={`sections[${ind}].subs[${index}].method`}
                                            rules={{
                                                maxLength: {
                                                    value: 2000,
                                                    message: 'Maximum length is 2000 characters'
                                                },
                                            }}
                                            render={({ field }) => {
                                                const { onChange, value } = field;
                                                return (
                                                    <TextField
                                                        type="textarea"
                                                        classNameChild="w-full block min-h-[90px]"
                                                        placeholder="方法"
                                                        value={value}
                                                        onChange={onChange}
                                                        onerror={errors?.sections && errors?.sections[ind]?.subs && errors.sections[ind]?.subs[index]?.method ? true : false}
                                                        id={`sections[${ind}].subs[${index}].method`}
                                                        readOnly={isViewOnly}
                                                    />
                                                );
                                            }}
                                        />
                                    </Label>
                                </div>
                                <div>
                                    <Label
                                        label="学生の活動"
                                        textTooltip="学生の活動"
                                        required = {!isViewOnly}
                                        textNote={errors?.sections && errors?.sections[ind]?.subs && errors.sections[ind]?.subs[index]?.activity && errors.sections[ind]?.subs[index]?.activity?.message}
                                        onerror={errors?.sections && errors?.sections[ind]?.subs && errors.sections[ind]?.subs[index]?.activity ? true : false}
                                        isShowLabel={false}
                                    >
                                        <Controller
                                            control={control}
                                            name={`sections[${ind}].subs[${index}].activity`}
                                            rules={{
                                                maxLength: {
                                                    value: 2000,
                                                    message: 'Maximum length is 2000 characters'
                                                },
                                            }}
                                            render={({ field }) => {
                                                const { onChange, value } = field;
                                                return (
                                                    <TextField
                                                        type="textarea"
                                                        classNameChild="w-full block min-h-[90px]"
                                                        placeholder="学生の活動"
                                                        value={value}
                                                        onChange={onChange}
                                                        onerror={errors?.sections && errors?.sections[ind]?.subs && errors.sections[ind]?.subs[index]?.activity ? true : false}
                                                        id={`sections[${ind}].subs[${index}].activity`}
                                                        readOnly={isViewOnly}
                                                    />
                                                );
                                            }}
                                        />
                                    </Label>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}