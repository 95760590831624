import { displayTypeSection, replaceHTML } from "../../../utils/common";

const TabContent2 = ({ courseInfo }: any) => {
  return (
    <>
      <div className="flex flex-col gap-4">
        <table className="w-full bg-white border border-blue-100 rounded-lg shadow-lg text-sm">
          <tr className="text-color-blue-600 h-14 align-middle text-md">
            <th className="p-4 text-left bg-blue-50 border-b border-r border-blue-100 w-1/5 font-semibold">目的</th>
            <td className="p-4 text-left bg-blue-50 border-b border-r border-blue-100"
              dangerouslySetInnerHTML={{
                __html: replaceHTML(courseInfo?.objective),
              }}>
            </td>
          </tr>
          <tr className="text-color-blue-600 h-14 align-middle">
            <th className="p-4 text-left bg-blue-50 border-b border-r border-blue-100 w-1/5">クラスの達成目標</th>
            <td className="p-4 text-left bg-blue-50 border-b border-r border-blue-100"
              dangerouslySetInnerHTML={{
                __html: replaceHTML(courseInfo?.goal),
              }}>
            </td>
          </tr>
          <tr className="text-color-blue-600 h-14 align-middle">
            <th className="p-4 text-left bg-blue-50 border-b border-r border-blue-100 w-1/5">目標達成の測定方法</th>
            <td className="p-4 text-left bg-blue-50 border-b border-r border-blue-100"
              dangerouslySetInnerHTML={{
                __html: replaceHTML(courseInfo?.method),
              }}>
            </td>
          </tr>
        </table>

        <table className="border-separate rounded border-spacing-0 w-full border border-color-blue-100 text-sm table-group-column">
          <thead>
            <tr className="bg-color-blue-50 text-color-blue-600 font-semibold">
              <td className="border-r border-r-color-blue-100 p-4 h-14 align-middle">
                構成
              </td>
              <td
                className="border-r border-r-color-blue-100 p-4 h-14 align-middle"
              >
                内容
              </td>
              <td className="border-r border-r-color-blue-100 p-4 h-14 align-middle">
                方法
              </td>
              <td className="p-4 h-14 align-middle last:border-r-0">
                学生の活動
              </td>
            </tr>
          </thead>
          <tbody>
            {courseInfo?.sections?.map((course: any, index: any) => (
              <>
                {course?.subs && course?.subs?.map((sub: any, ind: any) => (
                  <>
                    <tr className="bg-color-gray-20">
                      {ind === 0 ?
                        (
                          <>
                            <td
                              width="100px"
                              className="border-r border-r-color-blue-100 px-4 py-2 h-24 align-top text-color-blue-900 font-semibold"
                              rowSpan={course?.subs.length}
                            >
                              {displayTypeSection(courseInfo, index, courseInfo?.sections[index].type)}
                              <br />
                              {course?.subs?.[0].duration}
                            </td>
                            <td
                              width="18.7%"
                              className="border-r border-r-color-blue-100 px-4 py-2 h-24 align-top text-color-blue-900"
                              dangerouslySetInnerHTML={{
                                __html: replaceHTML(sub?.content),
                              }}>
                            </td>
                            <td
                              width="26%"
                              className="border-r border-r-color-blue-100 px-4 py-2 h-24 align-top text-color-blue-900"
                              dangerouslySetInnerHTML={{
                                __html: replaceHTML(sub?.method),
                              }}>
                            </td>
                            <td className="px-4 py-2 h-24 align-top text-color-blue-900 last:border-r-0"
                              dangerouslySetInnerHTML={{
                                __html: replaceHTML(sub?.activity),
                              }}>
                            </td>
                          </>
                        ) :
                        (
                          <>
                            <td className="border-r border-r-color-blue-100 px-4 py-2 h-24 align-top text-color-blue-900">
                              {sub?.title}
                            </td>
                            <td className="border-r border-r-color-blue-100 px-4 py-2 h-24 align-top text-color-blue-900"
                              dangerouslySetInnerHTML={{
                                __html: replaceHTML(sub?.content),
                              }}>
                            </td>
                            <td className="border-r border-r-color-blue-100 px-4 py-2 h-24 align-top text-color-blue-900"
                              dangerouslySetInnerHTML={{
                                __html: replaceHTML(sub?.method),
                              }}>
                            </td>
                            <td className="px-4 py-2 h-24 align-top text-color-blue-900 last:border-r-0"
                              dangerouslySetInnerHTML={{
                                __html: replaceHTML(sub?.activity),
                              }}>
                            </td>
                          </>
                        )}
                    </tr >
                  </>
                ))}
              </>
            ))}
          </tbody>
        </table>
      </div >
    </>
  );
};
export default TabContent2;
