import { rc, RouteKey } from '../../constants/router';
import { Link, useNavigate } from 'react-router-dom';
import AuthService from '../../services/AuthService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { UserContextType, useUser } from '../../contexts/UserContext';
import { BasicModalConfirm } from '../molecules/BasicModalConfirm';
import Button from '../atoms/Button';
import { useState } from 'react';
import { getConfirmRedirect } from '../../helper/sessionStorage';
import { handleConfirmRedirect, handleRedirect } from '../../utils/common';

export const Header = () => {
  const navigate = useNavigate();
  const { currentUser }: UserContextType = useUser();
  const [isModalConfirmRedirect, setIsModalConfirmRedirect] = useState<boolean>(false);
  const [redirectLink, setRedirectLink] = useState<string>('');
  const [isLogOut, setIsLogOut] = useState<boolean>(false);

  const handleLogout = () => {
    const confirmRedirect = getConfirmRedirect();
    if (confirmRedirect) {
      setIsModalConfirmRedirect(true);
      setRedirectLink('/login');
      setIsLogOut(true);
    } else {
      AuthService.logout();
      navigate(rc(RouteKey.Login).path);
    }
  };
  const handleOk = () => {
    setIsModalConfirmRedirect(false);
    if(isLogOut){
      AuthService.logout();
    }
    handleConfirmRedirect(redirectLink, navigate);
  }

  const handleCancel = () => {
    setIsModalConfirmRedirect(false);
    setRedirectLink('');
    setIsLogOut(false);
  }
  return (
    <>
      <header className="border-b border-b-color-gray-f2 py-2.5 bg-white">
        <div className="px-8 mx-auto">
          <div className="flex justify-between items-center">
            <Link to={rc(RouteKey.Home).path} onClick={(e) => handleRedirect(e, rc(RouteKey.Home).path, setIsModalConfirmRedirect, setRedirectLink)}>
              <span className="text-xl text-color-blue-500">
                <span className="font-black">CS</span> Assessment
              </span>
            </Link>
            <div className="flex gap-x-6 items-center">
              <div className="text-base">
                <span className="text-color-gray-80">Hi,</span>{' '}
                {currentUser?.username || ''}
              </div>
              <button
                onClick={handleLogout}
                className="border border-color-red-500 text-color-red-500 text-base leading-[1.5] font-semibold rounded-md px-4 py-1"
              >
                Logout{' '}
                <FontAwesomeIcon
                  className="ml-1"
                  icon={faArrowRightFromBracket}
                />
              </button>
            </div>
          </div>
        </div>
      </header>
      <BasicModalConfirm
        title="確認"
        open={isModalConfirmRedirect}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        footer={[
          <div className="grid grid-cols-2 gap-4">
            <div>
              <Button
                type="button"
                variant="outline-tertiary"
                className="w-full"
                onClick={handleCancel}
              >
                キャンセル
              </Button>
            </div>
            <div>
              <Button
                type="button"
                variant="primary"
                className="w-full"
                onClick={handleOk}
              >
                OK
              </Button>
            </div>
          </div>,
        ]}
      >
        {getConfirmRedirect()}
      </BasicModalConfirm>
    </>
  );
};
