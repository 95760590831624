import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faRotateRight,
  faPencil,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { criteriaListService } from '../../../services/CSSevice';
import { ICriteriaSchema } from '../../../constants/types';
import {
  convertToWhiteCircledNumber,
  findObjectIndex,
} from '../../../utils/common';
import { Controller } from 'react-hook-form';
import { IPropsEvaluation } from '..';

const TabContent3 = (props: IPropsEvaluation) => {
  const {
    courseInfo,
    EvaluationForm,
    showModal,
    setPromptDT,
    resCheck,
    setResCheck,
    isSubmit,
    setIsSubmit,
    setContentText,
    curTab,
    setIsTemporarySaved,
    isModalOpen,
    valueChange,
    setValueChange,
  } = props;
  const { control, setValue, getValues } = EvaluationForm;
  const [criterials, setCriterials] = useState<any>();
  const [activeCmt, setActiveCmt] = useState(-1);
  const [cmtValues, setCmtValues] = useState<any[]>([]);

  const evaluations = ['', '', ''];
  const colorUnslected = [
    'bg-color-green-5',
    'bg-color-gray-10',
    'bg-color-red-05',
  ];

  const colorSelected = [
    'bg-color-green-50',
    'bg-color-gray-30',
    'bg-color-red-50',
  ];
  const getColorBackground = (isSelected: boolean, index: number) => {
    const color = isSelected ? colorSelected : colorUnslected;
    return color[index];
  }

  useEffect(() => {
    const fetch = async () => {
      await criteriaListService().then(result => setCriterials(result));
    };
    fetch();
  }, []);

  useEffect(() => {
    if (resCheck && isSubmit && curTab === '3') {
      const positon = findObjectIndex(
        courseInfo?.criteria,
        resCheck?.criteria_id,
        'type',
      );
      setActiveCmt(positon);

      const updatedValueInArray = courseInfo?.criteria.map(
        (_: any, index: number) => {
          if (index === positon) {
            return resCheck?.result !== null ? resCheck?.result : '';
          } else {
            return cmtValues[index];
          }
        },
      );
      setValueChange('');
      setCmtValues(updatedValueInArray);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resCheck, isSubmit]);

  useEffect(() => {
    if (courseInfo && !resCheck) {
      const updateValue = courseInfo?.criteria?.map(
        (it: ICriteriaSchema, idx: number) => {
          const cmt =
            isSubmit ||
              activeCmt === -1 ||
              (valueChange !== getValues(`sections[${idx}].overall_comment`) &&
                activeCmt !== -1)
              ? getValues(`criteria[${idx}].comment`)
              : it.comment;
          if (cmtValues[idx] !== getValues(`criteria[${idx}].comment`)) {
            return cmt;
          }
          return cmtValues?.[idx];
        },
      );
      setCmtValues(updateValue);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCmt, courseInfo, resCheck, curTab]);

  useEffect(() => {
    if (isModalOpen || curTab !== '3') {
      setActiveCmt(-1);
    }
  }, [isModalOpen, curTab]);

  return (
    <>
      <div className="flex flex-col gap-4">
        <table className="border-separate rounded border-spacing-0 w-full border border-color-blue-100 text-sm table-simple">
          {courseInfo?.criteria?.map((it: ICriteriaSchema, idx: number) => (
            <>
              <tr className="bg-color-blue-50 text-color-blue-600 font-semibold">
                <td className="border-r border-r-color-blue-100 p-4 h-14 align-middle">
                  評価基準 {convertToWhiteCircledNumber(idx + 1)}
                </td>
                <td
                  className="p-4 h-14 align-middle last:border-r-0"
                  colSpan={3}
                >
                  {criterials?.[idx]?.name}
                </td>
              </tr>

              <tr>
                <td className="border-r border-r-color-blue-100 p-4 h-28 align-top text-color-blue-900">
                  評価
                </td>
                <Controller
                  control={control}
                  name={`criteria[${idx}].rating`}
                  render={({ field }) => {
                    const { value } = field;
                    return (
                      <tr className="grid grap-3 grid-cols-3">
                        {evaluations?.map((_, index: number) => (
                          <td
                            className={`border-r-color-blue-100 p-4 h-28 align-top text-color-blue-900 
                             ${getColorBackground(value === index, index)} ${value === index ? '!border-4 !border-color-blue-300' : 'border-r'} ${criterials?.[idx]?.[`rating_label_${index + 1}`] ? 'cursor-pointer' : 'cursor-auto'}`}
                            key={`evaluation_${index}`}
                            onClick={() => {
                              if (
                                criterials?.[idx]?.[`rating_label_${index + 1}`]
                              ) {
                                setValue(`criteria[${idx}].rating`, index);
                              }
                            }}
                          >
                            {criterials?.[idx]?.[`rating_label_${index + 1}`]}
                          </td>
                        ))}
                      </tr>
                    );
                  }}
                />
              </tr>

              <tr
                className={`${idx === activeCmt ? 'active' : ''}`}
                key={`criteria_cmt_${idx}`}
              >
                <td className="border-r border-r-color-blue-100 p-4 h-36 align-top text-color-blue-900">
                  <div className="flex flex-col justify-between h-full relative z-[1]">
                    <div>コメント・改修案</div>
                    <div className="mt-1">
                      <div className="bg-white p-2 border-2 border-white rounded-[5px] inline-flex shadow-cs-2">
                        <button
                          type="button"
                          onClick={() => {
                            showModal();
                            setActiveCmt(idx);
                            setIsSubmit(false);
                            if (
                              activeCmt !== idx ||
                              (activeCmt === idx && resCheck)
                            ) {
                              setResCheck();
                            }
                            setPromptDT({
                              field: 'comment',
                              criteria_id: criterials?.[idx]?.id,
                            });
                            setContentText(
                              getValues(`criteria[${idx}].comment`) || '',
                            );
                          }}
                          className="rounded border-0 p-0 font-normal inline-flex items-center justify-center bg-color-gray-20 w-7 h-7 text-color-blue-300 me-2 text-xl hover:bg-color-red-50 hover:text-color-red-600"
                        >
                          <FontAwesomeIcon icon={faRotateRight} />
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            setActiveCmt(idx);
                            if (activeCmt !== idx) {
                              setResCheck();
                            }
                          }}
                          className="rounded border-0 p-0 font-normal inline-flex items-center justify-center bg-color-gray-20 w-7 h-7 text-color-blue-300 me-2 text-xl hover:bg-color-blue-50 hover:text-color-blue-500"
                        >
                          <FontAwesomeIcon icon={faPencil} />
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            setValue(
                              `criteria[${idx}].comment`,
                              cmtValues[idx],
                            );
                            if (activeCmt !== idx && activeCmt !== -1) {
                              setIsTemporarySaved(true);
                            } else setIsTemporarySaved(false);
                            setActiveCmt(-1);
                          }}
                          className="rounded border-0 p-0 font-normal inline-flex items-center justify-center bg-color-gray-20 w-7 h-7 text-color-blue-300 me-2 text-xl last:me-0 hover:bg-color-green-50 hover:text-color-green-600"
                        >
                          <FontAwesomeIcon icon={faCheck} />
                        </button>
                      </div>
                    </div>
                  </div>
                </td>
                <td
                  className="p-4 h-36 align-top text-color-blue-900 last:border-r-0"
                  colSpan={4}
                >
                  <Controller
                    control={control}
                    name={`criteria[${idx}].comment`}
                    render={({ field: { value } }) => {
                      const handleCmtChange = (
                        e: React.ChangeEvent<HTMLTextAreaElement>,
                      ) => {
                        const updatedCmtValues = [...cmtValues];
                        updatedCmtValues[idx] = e.target.value;
                        setValueChange(updatedCmtValues);
                        setCmtValues(updatedCmtValues);
                      };
                      return (
                        <textarea
                          className="text-color-blue-900 text-sm resize-none w-full h-full leading-5 hover:outline-0 focus:outline-0 focus-visible:outline-0 bg-white"
                          value={
                            cmtValues[idx] !== undefined
                              ? cmtValues[idx]
                              : value
                          }
                          disabled={activeCmt !== idx}
                          onChange={handleCmtChange}
                        />
                      );
                    }}
                  />
                </td>
              </tr>
            </>
          ))}
        </table>
      </div>
    </>
  );
};
export default TabContent3;
