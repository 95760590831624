import { BasicModal } from '../molecules/BasicModal';
import Label from '../atoms/Label';
import Checkbox from '../atoms/Checkbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../atoms/Button';
import DatePicker from 'antd/lib/date-picker';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import TextFieldForm from '../atoms/TextFieldForm';
import {
  CS_DEMO_STATUS,
  CS_EVAL_STATUS,
  dateFormat,
  DEMO_TYPE_SEARCH,
} from '../../constants';
import { useState } from 'react';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

const SearchAdvance = ({ isModalOpen, handleOk, handleCancel }: any) => {
  const searchParams = new URLSearchParams(window.location.search);
  const location = useLocation();
  const format = 'YYYY-MM-DD';
  const { RangePicker } = DatePicker;
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();
  const [csStatus, setCsStatus] = useState<number[]>(
    searchParams.get('cs_status')?.split(',').map(Number) || [],
  );
  const [demoStatus, setDemoStatus] = useState<number[]>(
    searchParams.get('demo_status')?.split(',').map(Number) || [],
  );
  const [demoType, setDemoType] = useState<number[]>(
    searchParams.get('demo_type')?.split(',').map(Number) || [],
  );
  const [csDate, setCsDate] = useState<[Dayjs | null, Dayjs | null]>([
    searchParams.get('submit_start')
      ? dayjs(searchParams.get('submit_start'))
      : null,
    searchParams.get('submit_end')
      ? dayjs(searchParams.get('submit_end'))
      : null,
  ]);
  const [demoDate, setDemoDate] = useState<[Dayjs | null, Dayjs | null]>([
    searchParams.get('demo_start')
      ? dayjs(searchParams.get('demo_start'))
      : null,
    searchParams.get('demo_end') ? dayjs(searchParams.get('demo_end')) : null,
  ]);

  const handleSearch = (_formData: any) => {
    const formData = {
      title: _formData.title,
      cs_status: csStatus.length === 0 ? '' : csStatus,
      demo_status: demoStatus.length === 0 ? '' : demoStatus,
      demo_type: demoType.length === 0 ? '' : demoType,
      submit_start: csDate[0] ? dayjs(csDate[0]).format(format) : '',
      submit_end: csDate[1] ? dayjs(csDate[1]).format(format) : '',
      demo_start: demoDate[0] ? dayjs(demoDate[0]).format(format) : '',
      demo_end: demoDate[1] ? dayjs(demoDate[1]).format(format) : '',
      ..._formData,
    };

    if (location.search.includes('hide')) {
      const paramHide = searchParams?.get('hide') || '0';
      formData.hide = paramHide;
    }

    let qs = Object.keys(formData)
      .map(key => {
        if (formData[key]) {
          return key + '=' + formData[key];
        } else {
          return '';
        }
      })
      .filter(o => o)
      .join('&');

    navigate(`?${qs}`);
    handleOk();
  };

  const handleCheckboxCSChange = (
    rowId: number,
    statusState: number[],
    setStatusState: any,
  ) => {
    const index = statusState.indexOf(rowId);

    if (index === -1) {
      setStatusState([...statusState, rowId]);
    } else {
      const newSelectedRows = [...statusState];
      newSelectedRows.splice(index, 1);
      setStatusState(newSelectedRows);
    }
  };

  const handleCheckboxDemoType = (id: number) => {
    const index = demoType.indexOf(id);

    if (index === -1) {
      setDemoType([...demoType, id]);
    } else {
      const newSelectedRows = [...demoType];
      newSelectedRows.splice(index, 1);
      setDemoType(newSelectedRows);
    }
  };

  const handleSetCsDate = (dates: any | null) => {
    if (dates) {
      if (dates instanceof Array) {
        const [startDate, endDate] = dates;

        if (startDate && endDate) {
          const formattedStartDate = dayjs(startDate);
          const formattedEndDate = dayjs(endDate);
          setCsDate([formattedStartDate, formattedEndDate]);
        }
      }
    } else {
      setCsDate([null, null]);
    }
  };

  const handleSetDemoDate = (dates: any | null) => {
    if (dates) {
      if (dates instanceof Array) {
        const [startDate, endDate] = dates;

        if (startDate && endDate) {
          const formattedStartDate = dayjs(startDate);
          const formattedEndDate = dayjs(endDate);
          setDemoDate([formattedStartDate, formattedEndDate]);
        }
      }
    } else {
      setDemoDate([null, null]);
    }
  };

  return (
    <BasicModal
      title="詳細検索"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      centered
      width="680px"
      footer={[
        <form onSubmit={handleSubmit(handleSearch)} id="search_form">
          <div className="grid">
            <Button
              type="submit"
              variant="primary"
              size="medium"
              className="w-full"
            >
              検索
            </Button>
          </div>
        </form>,
      ]}
    >
      <form id="search_form">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <Label label="タイトル" className="mb-5">
              <TextFieldForm
                type="text"
                placeholder="タイトル"
                classNameChild="w-full"
                {...register('title')}
                defaultValue={searchParams.get('title')}
              />
            </Label>
          </div>
          <div>
            <Label label="CS提出日" className="mb-5">
              <RangePicker
                popupClassName="cpickerdropdown"
                placeholder={[dateFormat, dateFormat]}
                format={dateFormat}
                separator={
                  <FontAwesomeIcon
                    className="text-color-grey-01 opacity-[0.87]"
                    icon={faArrowRight}
                  />
                }
                value={csDate[0] && csDate[1] ? csDate : null}
                size="middle"
                onChange={e => {
                  handleSetCsDate(e);
                }}
              />
            </Label>
          </div>
        </div>

        <div className="text-base font-semibold text-color-gray-06">
          CSステータス
        </div>
        <div className="grid grid-cols-4 gap-y-2 gap-x-2 mb-5">
          {CS_EVAL_STATUS?.map((item, index) => (
            <div>
              <Checkbox
                id={`cs_${index}`}
                label={item}
                onChange={() => {
                  handleCheckboxCSChange(index, csStatus, setCsStatus);
                }}
                checked={searchParams
                  .get('cs_status')
                  ?.split(',')
                  .includes(String(index))}
              />
            </div>
          ))}
        </div>

        <div className="text-base font-semibold text-color-gray-06">
          模擬授業ステータス
        </div>
        <div className="grid grid-cols-4 gap-y-2 gap-x-2 mb-5">
          {CS_DEMO_STATUS?.map((item, index) => (
            <>
              {item && (
                <Checkbox
                  id={`demo_status_${index}`}
                  label={item}
                  onChange={() => {
                    handleCheckboxCSChange(index, demoStatus, setDemoStatus);
                  }}
                  checked={searchParams
                    .get('demo_status')
                    ?.split(',')
                    .includes(String(index))}
                />
              )}
            </>
          ))}
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <Label label="模擬授業日" className="mb-5">
              <RangePicker
                popupClassName="cpickerdropdown"
                placeholder={[dateFormat, dateFormat]}
                format={dateFormat}
                separator={
                  <FontAwesomeIcon
                    className="text-color-grey-01 opacity-[0.87]"
                    icon={faArrowRight}
                  />
                }
                value={demoDate[0] && demoDate[1] ? demoDate : null}
                size="middle"
                onChange={value => {
                  handleSetDemoDate(value);
                }}
              />
            </Label>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <div className="text-base font-semibold text-color-gray-06">
              授業タイプ
            </div>
            <div className="grid grid-cols-2 gap-y-3 gap-x-4 mb-5">
              {DEMO_TYPE_SEARCH?.map(item => (
                <div>
                  <Checkbox
                    id={`demo_${item.value}`}
                    label={item.label}
                    onChange={() => {
                      handleCheckboxDemoType(item.value);
                    }}
                    checked={searchParams
                      .get('demo_type')
                      ?.split(',')
                      .includes(String(item.value))}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </form>
    </BasicModal>
  );
};
export default SearchAdvance;
