import { rc, RouteKey } from "../../constants/router";
import { regexRouter } from "../../utils/common";

const HomePage = () => {
    return (
        <div className="bg-gray-50 min-h-screen flex flex-col items-center">
            {/* Header */}
            <header className="bg-white w-full flex flex-col p-4 shadow-md sticky top-0 z-10">
                <div className="flex items-center w-full max-w-4xl">
                    <img src="/assets/logo_header.png" alt="University Logo" className="h-20 mr-6" />
                    <img src="./assets/logo_header_2.svg" alt="Faculty Development Logo" />
                </div>
            </header>

            {/* Campus Image */}
            <div className="relative mb-6 w-full">
                <img
                    src="/assets/campus_background.jpg"
                    alt="Campus"
                    className="w-full bg-no-repeat bg-cover"
                />
            </div>
            {/* Title */}
            <div className="flex justify-center items-center mb-4">
                <img src="/assets/class_plan_eval_logo.png" alt="Class Plan Eval" className="h-28" />
                <h1 className="text-5xl font-bold text-blue-800">Class Plan Eval</h1>
            </div>

            {/* Main Content */}
            <main className="max-w-8xl mt-8 p-8 text-center">

                <div className="flex justify-center mb-4">
                    <div className="w-1/2 ml-28">
                        <div className="p-4 text-gray-700 leading-relaxed text-sm">
                            <p className="text-left mb-6" style={{ fontSize: "20px" }}>
                                <span className="text-blue-600" style={{ fontSize: "20px" }}>『Class Plan Eval』</span> は、大学教員を目指す方や、現職の教員が教授力を向上させるためのAIシステムです。
                                授業計画を作成し、AIによる自動評価を受けることができます。<br />
                                優れた評価を得た方は、Zoomまたは動画形式で模擬授業を申請でき、東京大学フューチャー・ファカルティ・プログラムに従事する教員・サポーターが立ち会います。<br />
                                ご利用にあたり、アカウントの登録が必要です。
                                また、本システムは、大学総合教育研究センターが提供する教育プログラム、「東京大学フューチャーファカルティプログラム」、「インタラクティブ・ティーチング」、「6分間の模擬授業講座」を修了することでご利用が可能となります。
                                <br />
                                皆様の参加をぜひ、お待ちしております。
                                <br />
                            </p>
                            <div className="flex items-center">
                                ⇒
                                <a href={regexRouter(rc(RouteKey.Login).path)}
                                    className="text-blue-600 ml-2" style={{ fontSize: "20px" }}>システムへのログインはこちらから
                                </a>
                            </div>
                            <br />
                            <div className="flex items-center">
                                ⇒
                                <a href="/assets/manual.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer" className="text-blue-600 ml-2" style={{ fontSize: "20px" }}>操作マニュアルはこちらから
                                </a>
                                <img
                                    src="/assets/logo_pdf.png"
                                    alt="pdf"
                                    className="h-10"
                                />
                            </div>
                            <br />
                            <div className="flex items-center">
                                ⇒<a
                                    href="https://docs.google.com/forms/d/e/1FAIpQLScKaIHzYVWio66K96ewH5MJnAMmAWjhT7w9iWm3GSow7TT_WA/viewform?usp=sharing"
                                    target="_blank"
                                    className="text-blue-600 ml-2"
                                    style={{ fontSize: "20px" }}
                                >
                                    よくあるご質問・お問い合わせはこちらから
                                </a>
                            </div>
                            <br />
                            <div className="flex items-center">
                                ⇒<a
                                    href="https://www.he.u-tokyo.ac.jp/privacy_policy/"
                                    target="_blank"
                                    className="text-blue-600 ml-2"
                                    style={{ fontSize: "20px" }}
                                >
                                    プライバシーポリシーはこちらから
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="w-1/2 mr-28">
                        <div className="flex justify-end">
                            <img src="/assets/sample_1.png" alt="image 1" className="w-3/4 h-1/2 border-2 border-gray-500 mb-4" />
                        </div>
                        <div className="flex justify-end">
                            <img src="/assets/sample_2.png" alt="image 2" className="w-3/4 h-1/2 border-2 border-gray-500" />
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default HomePage;
