import { BasicModal } from "../../../components/molecules/BasicModal";
import { Loader } from "../../../components/atoms/Loader";
import Label from "../../../components/atoms/Label";
import { Controller, useForm } from "react-hook-form";
import TextFieldForm from "../../../components/atoms/TextFieldForm";
import {
    createUserSuccess,
    emailAlreadyExists,
    fullWidth1000characters,
    fullWidth100characters,
    fullWidth70characters,
    systemError,
    txtEmailRequired,
    txtFirstNameRequired,
    txtGenderRequired,
    txtUserIdRequeried,
    txtValidateCustomEmail,
    txtValidateUserIdCustom,
    usernameAlreadyExists,
    validateUserName,
} from "../../../constants/message";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { genderData } from "../../../constants";
import SelectField from "../../../components/atoms/Select";
import { IRegisterAccount } from "../../../constants/types";
import Button from "../../../components/atoms/Button";
import AuthService from "../../../services/AuthService";
import { useState } from "react";
import { toast } from "react-toastify";
import ToastMessageCommon from "../../../components/atoms/ToastMessageCommon";

const RegiterModal = ({ isOpenRegiter, setIsOpenRegiter }: any) => {
    const {
        control,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<IRegisterAccount>();
    const [isLoadingModal, setIsLoadingModal] = useState(false);

    const handleSubmitRegister = async () => {
        setIsLoadingModal(true);
        const data = {
            ...watch(),
            roles: [0], // role default : user 
        };
        await AuthService.registerAccount(data).then(r => {
            if (r === 201) {
                setIsOpenRegiter(false);
                toast.success(
                    ToastMessageCommon({
                        title: '',
                        text: createUserSuccess,
                    }),
                    { containerId: 'register-account' },
                );
            } else {
                let textError = ''
                if (r === 'username') {
                    textError = usernameAlreadyExists;
                } else if (r === 'email') {
                    textError = emailAlreadyExists;
                } else {
                    textError = systemError;
                }
                toast.error(
                    ToastMessageCommon({
                        title: '',
                        text: textError,
                    }),
                    { containerId: 'register-account' },
                );
                setIsLoadingModal(false);
            }
        });
    }

    return (
        <>
            <BasicModal
                title={'アカウントの新規作成'}
                open={isOpenRegiter}
                onCancel={() => { setIsOpenRegiter(false) }}
                centered
                width="915px"
                footer={[
                    <form onSubmit={handleSubmit(handleSubmitRegister)}>
                        <div className="text-right">
                            <Button
                                type="submit"
                                variant="primary"
                                size="medium"
                                className="min-w-[294px]"
                            >
                                新規作成
                            </Button>
                        </div>
                    </form>,
                ]}
            >
                <Loader isLoading={isLoadingModal}>
                    <div className="grid grid-cols-2 gap-4">
                        <Label label="ユーザーID" className="mb-5" required={true}>
                            <Controller
                                control={control}
                                name="username"
                                rules={{
                                    required: txtUserIdRequeried,
                                    pattern: {
                                        value: /^[a-zA-Z0-9@.+\-_]+$/,
                                        message: txtValidateUserIdCustom,
                                    },
                                    maxLength: {
                                        value: 30,
                                        message: txtValidateUserIdCustom,
                                    },
                                }}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <TextFieldForm
                                            type="text"
                                            placeholder="ユーザーID"
                                            classNameChild="w-full"
                                            value={value}
                                            onChange={onChange}
                                            onerror={!!errors.username?.message}
                                            errorMessage={errors.username?.message}
                                        />
                                    );
                                }}
                            />
                        </Label>

                        <Label label="メールアドレス" className="mb-5" required={true}>
                            <Controller
                                control={control}
                                name="email"
                                rules={{
                                    required: txtEmailRequired,
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: txtValidateCustomEmail,
                                    },
                                }}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <TextFieldForm
                                            type="text"
                                            placeholder="email@example.com"
                                            classNameChild="w-full"
                                            onChange={onChange}
                                            value={value}
                                            onerror={!!errors.email?.message}
                                            errorMessage={errors.email?.message}
                                        />
                                    );
                                }}
                            />
                        </Label>
                    </div>

                    <div
                        className={`grid gap-4 grid-cols-3`}
                    >
                        <Label label="性別" className="mb-5" required>
                            <Controller
                                control={control}
                                name="gender"
                                rules={{
                                    required: txtGenderRequired,
                                }}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <>
                                            <SelectField
                                                classNameChild="w-full appearance-none"
                                                onChange={onChange}
                                                value={value}
                                                options={genderData}
                                                iconRight={faChevronDown}
                                                onerror={!!errors.gender?.message}
                                            />
                                            <span className="text-red-600">
                                                {!!errors.gender?.message && txtGenderRequired}
                                            </span>
                                        </>
                                    );
                                }}
                            />
                        </Label>
                        <Label label="氏名" className="mb-5" required>
                            <Controller
                                control={control}
                                name="first_name"
                                rules={{
                                    required: txtFirstNameRequired,
                                    pattern: {
                                        value: /^[a-zA-Zぁ-んァ-ン一-龥ｧ-ﾝﾞﾟ]+$/,
                                        message: validateUserName,
                                    },
                                    maxLength: {
                                        value: 50,
                                        message: validateUserName,
                                    },
                                }}
                                render={({ field: { value, onChange } }) => {
                                    return (
                                        <TextFieldForm
                                            type="text"
                                            placeholder="氏名"
                                            classNameChild="w-full"
                                            value={value}
                                            onChange={onChange}
                                            onerror={!!errors.first_name?.message}
                                            errorMessage={errors.first_name?.message}
                                        />
                                    );
                                }}
                            />
                        </Label>

                        <Label label="所属" className="mb-5">
                            <Controller
                                control={control}
                                name="affiliation"
                                rules={{
                                    maxLength: {
                                        value: 100,
                                        message: fullWidth100characters,
                                    },
                                }}
                                render={({ field: { value, onChange } }) => {
                                    return (
                                        <TextFieldForm
                                            type="text"
                                            placeholder="所属を入力してください"
                                            classNameChild="w-full"
                                            value={value}
                                            onChange={onChange}
                                            onerror={!!errors.affiliation?.message}
                                            errorMessage={errors.affiliation?.message}
                                        />
                                    );
                                }}
                            />
                        </Label>
                    </div>

                    <Label label="職位" className="mb-5">
                        <Controller
                            control={control}
                            name="position"
                            rules={{
                                maxLength: {
                                    value: 70,
                                    message: fullWidth70characters,
                                },
                            }}
                            render={({ field: { value, onChange } }) => {
                                return (
                                    <TextFieldForm
                                        type="text"
                                        placeholder="職位を入力してください"
                                        classNameChild="w-full"
                                        value={value}
                                        onChange={onChange}
                                        onerror={!!errors.position?.message}
                                        errorMessage={errors.position?.message}
                                    />
                                );
                            }}
                        />
                    </Label>

                    <Label label="教育歴" className="mb-5">
                        <Controller
                            control={control}
                            name="education"
                            rules={{
                                maxLength: {
                                    value: 1000,
                                    message: fullWidth1000characters,
                                },
                            }}
                            render={({ field: { value, onChange } }) => {
                                return (
                                    <TextFieldForm
                                        type="textarea"
                                        placeholder="教育を入力してください"
                                        classNameChild="w-full"
                                        value={value}
                                        onChange={onChange}
                                        onerror={!!errors.education?.message}
                                        errorMessage={errors.education?.message}
                                    />
                                );
                            }}
                        />
                    </Label>
                    <Label label="備考" className="mb-0">
                        <Controller
                            control={control}
                            name="remark"
                            rules={{
                                maxLength: {
                                    value: 1000,
                                    message: fullWidth1000characters,
                                },
                            }}
                            render={({ field: { value, onChange } }) => {
                                return (
                                    <TextFieldForm
                                        type="textarea"
                                        placeholder="コメントを入力してください"
                                        classNameChild="w-full"
                                        value={value}
                                        onChange={onChange}
                                        onerror={!!errors.remark?.message}
                                        errorMessage={errors.remark?.message}
                                    />
                                );
                            }}
                        />
                    </Label>
                </Loader>
            </BasicModal>
        </>
    )
}

export default RegiterModal;