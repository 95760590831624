import { IMailTemplate } from '../constants/types';
import mainAxiosInstance from './api';
class ContactService {
  static async getListContact(params: any): Promise<any> {
    try {
      const response = await mainAxiosInstance.get<IMailTemplate>(
        `settings/contracts/`,
        {params}
      );
      return response;

    } catch (error) {
    }
  }

  static async createContact(params: any): Promise<any> {
    try {
      const response = await mainAxiosInstance.post<IMailTemplate>(
        `settings/contracts/`,
        {...params}
      );
      return response;

    } catch (error) {
    }
  }

  static async deleteContact(contactId: number): Promise<any> {
    try {
      const response = await mainAxiosInstance.delete(
        `settings/contracts/${contactId}/`,
        {},
      );
      return response;
    } catch (error) {
      return false;
    }
  }

  static async updateContact(contactId: number, params: any): Promise<any> {
    try {
      const response = await mainAxiosInstance.put(
        `settings/contracts/${contactId}/`,
        {...params},
      );
      return response;
    } catch (error) {
      return false;
    }
  }

}

export default ContactService;
