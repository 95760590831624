import { useEffect, useState } from 'react';
import { Heading } from '../../components/templates/Heading';
import { TabLinks } from '../../components/templates/TabLinks';
import { Tab, TabPane } from '../../components/atoms/Tab';
import Label from '../../components/atoms/Label';
import TextField from '../../components/atoms/TextField';
import TabContent1 from './included/TabContent1';
import TabContent2 from './included/TabContent2';
import TabContent3 from './included/TabContent3';
import 'react-toastify/dist/ReactToastify.css';
import { Radio } from 'antd/lib';
import styled from 'styled-components';
import { ArrowLeft, RATING_EXEMPLARY, RATING_IMPROVEMENT, RATING_STANDARD, TYPE_SECTIONS } from '../../constants';
import { ICourseSchema, ICriteriaSchema } from '../../constants/types';
import { ListToastContainer } from '../../components/atoms/ToastMessage';
import { useNavigate, useParams } from 'react-router-dom';
import { RouteKey, rc } from '../../constants/router';
import CourseService from '../../services/CourseService';
import { regexRouter } from '../../utils/common';
import { Loader } from '../../components/atoms/Loader';
import { getProfile } from '../../helper/localStorage';
import RequestDemoDialog from '../../components/organisms/RequestDemoDialog';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import CriteriaService from '../../services/CriteriaService';
import Button from '../../components/atoms/Button';
// style common
const fontStyleSheet2: any = {
  name: 'Calibri',
  size: 12,
  color: { argb: 'FF000000' }
}
const alignmentCenterStyle: any = { vertical: 'middle', horizontal: 'center' }
const fontStyleSheet3: any = {
  name: 'Meiryo UI',
  size: 11,
  color: { argb: 'FF000000' }
};
const cssBorderDefault: any = { style: 'thin', color: { argb: 'FF000000' } };
const borderStyle: any = {
  top: cssBorderDefault,
  left: cssBorderDefault,
  bottom: cssBorderDefault,
  right: cssBorderDefault
};
const fontStyleSheet1: any = {
  name: 'Meiryo',
  size: 12,
  color: { argb: 'FF000000' }
};
const alignmentStyle: any = { vertical: 'middle', horizontal: 'left', wrapText: true };

const EvaluationView = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [courseInfo, setCourseInfo] = useState<ICourseSchema>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [criteria, setCriteria] = useState<ICriteriaSchema[]>();

  const { id } = useParams();
  const navigate = useNavigate();
  const profile = getProfile();
  const userID = profile && JSON.parse(profile).id;
  const statusShow = [4, 6];

  useEffect(() => {
    setIsLoading(true);
    const fetch = async () => {
      CourseService.getDataCourse(id || '').then(response => {
        if (response.status === 200 && response.data.status >= 4) {
          setCourseInfo(response.data);
        } else {
          navigate(regexRouter(rc(RouteKey.Home).path));
        }
        setIsLoading(false);
      });
    };
    fetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    CriteriaService.getAllCriteria().then(response => {
      if (response) {
        setCriteria(response);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSheet2 = (worksheet: any) => {
    // Set up column headers with styles
    worksheet.columns = [
      { header: '', key: 'colA', width: 10 },
      { header: '', key: 'colB', width: 24 },
      { header: '', key: 'colC', width: 140 }
    ];

    const dataSheet2 = [
      { colA: '', colB: 'コメント', colC: courseInfo?.overall_comment },
      { colA: '', colB: '目的', colC: courseInfo?.objective },
      { colA: '', colB: 'コメント', colC: courseInfo?.objective_comment },
      { colA: '', colB: 'クラスの達成目標', colC: courseInfo?.goal },
      { colA: '', colB: 'コメント', colC: courseInfo?.goal_comment },
      { colA: '', colB: '目標達成の測定方法', colC: courseInfo?.method },
      { colA: '', colB: 'コメント', colC: courseInfo?.method_comment },
    ];

    const applyCellStyle = (cell: any, { fill, font, border, alignment }: any) => {
      if (fill) cell.fill = fill;
      if (font) cell.font = font;
      if (border) cell.border = border;
      if (alignment) cell.alignment = alignment;
    };

    const setHeaderCell = (cell: any, value: string, fillColor: string) => {
      applyCellStyle(cell, {
        fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: fillColor } },
        font: fontStyleSheet2,
        border: borderStyle,
        alignment: alignmentCenterStyle,
      });
      cell.value = value;
    };

    // set only header 
    worksheet.getRow(2).height = 78;
    setHeaderCell(worksheet.getCell('B2'), '総合評価', 'EBF1DE');
    setHeaderCell(worksheet.getCell('C2'), courseInfo?.overall_rating_display ?? '', 'FFFFEF');

    dataSheet2.forEach((row, index) => {
      // start row 2
      const rowIndex = index + 3;
      worksheet.addRow(row);
      const allRow = worksheet.getRow(rowIndex);
      allRow.height = 78;

      applyCellStyle(worksheet.getCell(`B${rowIndex}`), {
        fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'EBF1DE' } },
        font: fontStyleSheet2,
        border: borderStyle,
        alignment: alignmentCenterStyle,
      });

      applyCellStyle(worksheet.getCell(`C${rowIndex}`), {
        font: fontStyleSheet2,
        border: borderStyle,
        alignment: { vertical: 'middle', horizontal: 'left', wrapText: true },
      });
    });
  }

  const handleSheet3 = (worksheet: any) => {
    // Set up column headers with styles
    worksheet.columns = [
      { header: '', key: 'colA', width: 3 },
      { header: 'ルーブリックでの評価', key: 'colB', width: 50 },
      { header: '', key: 'colC', width: 50 },
      { header: '', key: 'colD', width: 50 },
      { header: '', key: 'colE', width: 100 }
    ];

    worksheet.addRow({
      colB: '模範的',
      colC: '標準的',
      colD: '要改善',
      colE: 'コメント'
    })

    // handle row 1
    worksheet.mergeCells(`B1:E1`);
    const mergedCell = worksheet.getCell(`B1`);
    mergedCell.font = fontStyleSheet3;
    worksheet.getCell(`B1`).border = {
      top: cssBorderDefault,
      bottom: cssBorderDefault,
    };
    mergedCell.alignment = alignmentCenterStyle;
    worksheet.getRow(1).height = 30;

    // handle row 2 : header table
    const rowHeaderAll = worksheet.getRow(2);
    rowHeaderAll.height = 30;
    rowHeaderAll.alignment = alignmentCenterStyle;
    rowHeaderAll.font = fontStyleSheet3;
    const cells = ['B', 'C', 'D']
    cells.forEach(value => {
      const cell = worksheet.getCell(value + '2')
      cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'DAEEF3' } }
      cell.font = { ...fontStyleSheet3, bold: true }
      cell.border = borderStyle;
    });
    worksheet.getCell('E2').border = borderStyle;

    const applyCellStyle = (cell: any, fillColor: any) => {
      cell.font = fontStyleSheet3;
      cell.border = borderStyle;
      cell.alignment = alignmentStyle;
      if (fillColor) cell.fill = fillColor;
    };

    // add title evaluation in even row
    criteria?.forEach((cri, index) => {
      const rating = courseInfo?.criteria[index]?.rating;
      // idxTitle : 3,5,7,9,11,..
      const idxTitle = (index * 2) + 3;
      // idxContent : 4,6,8,10,...
      const idxContent = (index * 2) + 4;
      // merged cell B to E and handle row title of cell have idxTitle
      worksheet.mergeCells(`B${idxTitle}:E${idxTitle}`);
      worksheet.getRow(idxTitle).height = 30;
      const mergedCell = worksheet.getCell(`B${idxTitle}`);
      mergedCell.value = cri.name;
      applyCellStyle(mergedCell, { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFCC' } })

      // handle row content of cell have idxContent
      worksheet.getRow(idxContent).height = 100;
      const colSet = [
        { col: 'B', value: cri.rating_label_1, highlight: rating === RATING_EXEMPLARY },
        { col: 'C', value: cri.rating_label_2, highlight: rating === RATING_STANDARD },
        { col: 'D', value: cri.rating_label_3, highlight: rating === RATING_IMPROVEMENT },
        { col: 'E', value: courseInfo?.criteria[index]?.comment, highlight: false }
      ];
      colSet.forEach(({ col, value, highlight }) => {
        const cell = worksheet.getCell(`${col}${idxContent}`);
        applyCellStyle(cell, highlight ? { type: 'pattern', pattern: 'solid', fgColor: { argb: 'DDFFDD' } } : null);
        cell.value = value;
      });

    });
  }

  const handleSheet1 = (worksheet: any) => {
    worksheet.columns = [
      { header: '6分間クラスデザインシート入力テンプレート', key: 'colA', width: 10 },
      { header: '', key: 'colB', width: 10 },
      { header: '', key: 'colC', width: 10 },
      { header: '', key: 'colD', width: 40 },
      { header: '', key: 'colE', width: 40 },
      { header: '', key: 'colF', width: 34 }
    ];
    // function
    function setRowHeightBasedOnText(value: string, step: number) {
      const baseHeight = 15;
      const increment = 15;
      let height = 0;
      const ratio = Math.ceil(value.length / step);
      if (ratio >= 3) {
        height = 60;
      }
      return baseHeight + height + ratio * increment;
    }
    function findLongestStringLength(strings: string[]) {
      if (strings.length === 0) return '';
      const longestString = strings.reduce((longest, currentString) => {
        return currentString.length > longest.length ? currentString : longest;
      }, '');
      return longestString;
    }
    const applyCellStyle = (cell: any, { font, border, alignment, fill, value }: any) => {
      if (font) cell.font = font;
      if (border) cell.border = border;
      if (alignment) cell.alignment = alignment;
      if (fill) cell.fill = fill;
      if (value) cell.value = value;
    };
    function setBorder(cell: any, index: number, maxLength: number, color: string) {
      if (index === 0) {
        cell.border = {
          top: cssBorderDefault,
          right: cssBorderDefault,
          bottom: index === maxLength - 1 ? cssBorderDefault : { style: 'thin', color: { argb: color } },
        };
      } else if (index === maxLength - 1) {
        cell.border = {
          top: { style: 'thin', color: { argb: color } },
          bottom: cssBorderDefault,
          right: cssBorderDefault
        };
      } else {
        cell.border = {
          top: { style: 'thin', color: { argb: color } },
          bottom: { style: 'thin', color: { argb: color } },
          right: cssBorderDefault
        };
      }
    }

    function addTime(time1: string, time2: string) {
      const [minutes1, seconds1] = time1.split(':').map(Number);
      const [minutes2, seconds2] = time2.split(':').map(Number);

      let totalSeconds = seconds1 + seconds2;
      let totalMinutes = minutes1 + minutes2 + Math.floor(totalSeconds / 60);
      totalSeconds = totalSeconds % 60;

      const formattedMinutes = String(totalMinutes);
      const formattedSeconds = String(totalSeconds).padStart(2, '0');

      return `${formattedMinutes}:${formattedSeconds}`;
    };

    // Row 1
    worksheet.mergeCells(`A1:F1`);;
    worksheet.getRow(1).height = 30;
    applyCellStyle(worksheet.getCell(`A1`), {
      font: fontStyleSheet1,
      alignment: alignmentCenterStyle
    })
    // row 2 start cell A
    worksheet.mergeCells(`A2:C2`);
    worksheet.getRow(2).height = 20;
    applyCellStyle(worksheet.getCell(`A2`), {
      font: { ...fontStyleSheet1, bold: true },
      border: borderStyle,
      alignment: alignmentCenterStyle,
      value: 'ユーザー名'
    })
    // row 2 start cell D
    applyCellStyle(worksheet.getCell(`D2`), {
      font: { ...fontStyleSheet1, bold: true },
      border: borderStyle,
      alignment: alignmentCenterStyle,
      value: courseInfo?.user?.first_name ?? ''
    })
    // row 3,4
    worksheet.mergeCells(`A3:F3`);
    worksheet.mergeCells(`A4:F4`);
    worksheet.getRow(4).height = 20;
    applyCellStyle(worksheet.getCell(`A4`), {
      font: { ...fontStyleSheet1, bold: true },
      alignment: alignmentStyle,
      value: 'タイトル'
    })
    // row 5
    worksheet.mergeCells(`A5:F5`);
    worksheet.getRow(5).height = 20;
    applyCellStyle(worksheet.getCell(`A5`), {
      font: { ...fontStyleSheet1, bold: true },
      border: borderStyle,
      alignment: alignmentCenterStyle,
      fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F2DCDB' } },
      value: courseInfo?.title ?? ''
    })
    // row 6,7
    worksheet.mergeCells(`A6:F6`);
    worksheet.mergeCells(`A7:F7`);
    worksheet.getRow(7).height = 20;
    applyCellStyle(worksheet.getCell(`A7`), {
      font: { ...fontStyleSheet1, bold: true },
      alignment: alignmentStyle,
      value: 'このクラスの目的'
    })
    // row 8
    worksheet.mergeCells(`A8:F8`);
    const rowHeight = setRowHeightBasedOnText(courseInfo?.objective ?? '', 80);
    worksheet.getRow(8).height = rowHeight;
    applyCellStyle(worksheet.getCell(`A8`), {
      font: fontStyleSheet1,
      border: borderStyle,
      alignment: alignmentCenterStyle,
      fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFEF' } },
      value: courseInfo?.objective ?? ''
    })
    // row 9, 10
    worksheet.mergeCells(`A9:F9`);
    worksheet.getRow(10).height = 20;
    worksheet.mergeCells(`A10:D10`);
    applyCellStyle(worksheet.getCell(`A10`), {
      font: {
        name: 'Meiryo',
        size: 10,
        color: { argb: 'FF000000' },
        bold: true
      },
      border: borderStyle,
      alignment: alignmentCenterStyle,
      value: 'クラスの達成目標'
    })
    // row 10 start cell E
    worksheet.mergeCells(`E10:F10`);
    applyCellStyle(worksheet.getCell(`E10`), {
      font: {
        name: 'Meiryo',
        size: 10,
        color: { argb: 'FF000000' },
        bold: true
      },
      border: borderStyle,
      alignment: alignmentCenterStyle,
      value: '目標達成の測定方法'
    })
    // hanle goal and medthod
    const goal = courseInfo?.goal.split(/\n/);
    const method = courseInfo?.method.split(/\n/);
    let maxLength = 0;
    if (goal && method) {
      // get number of lines by goal or method
      maxLength = goal.length > method.length ? goal.length : method.length;
      for (let i = 0; i <= maxLength - 1; i++) {
        // start row 11
        const idx = 11 + i;
        const goalData = goal[i] ?? '';
        const methodData = method[i] ?? '';
        // merged row cell A to D
        worksheet.mergeCells(`A${idx}:D${idx}`);
        // merged row cell E to F
        worksheet.mergeCells(`E${idx}:F${idx}`);
        const cellA = worksheet.getCell(`A${idx}`);
        const cellE = worksheet.getCell(`E${idx}`);
        // set max height of cell
        const rowHeight = setRowHeightBasedOnText(findLongestStringLength([goalData, methodData]), 30);
        worksheet.getRow(idx).height = rowHeight;

        applyCellStyle(cellA, {
          font: fontStyleSheet1,
          alignment: alignmentStyle,
          fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'EBF1DE' } },
          value: goalData
        })
        setBorder(cellA, i, maxLength, 'EBF1DE');

        applyCellStyle(cellE, {
          font: fontStyleSheet1,
          alignment: alignmentStyle,
          fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'DAEEF3' } },
          value: methodData
        })
        setBorder(cellE, i, maxLength, 'DAEEF3');
      }
    }
    // handle title section after goal position
    const indexSection = 11 + (maxLength ? maxLength : 1);
    worksheet.mergeCells(`A${indexSection}:F${indexSection}`);
    worksheet.getRow(indexSection).height = 20;
    applyCellStyle(worksheet.getCell(`A${indexSection}`), {
      font: { ...fontStyleSheet1, bold: true },
      alignment: alignmentStyle,
      value: 'スケジュール'
    })
    // handle header section after tilte section position
    const indexHeaderSection = indexSection + 1;
    const colHeaderSection = [
      { col: 'A', value: '経過\n時間' },
      { col: 'B', value: '所要\n時間' },
      { col: 'C', value: '構成' },
      { col: 'D', value: '内容' },
      { col: 'E', value: '方法（発問などもここに）' },
      { col: 'F', value: '学生の活動' }
    ];
    worksheet.getRow(indexHeaderSection).height = 50;
    colHeaderSection.forEach(({ col, value }) => {
      applyCellStyle(worksheet.getCell(`${col}${indexHeaderSection}`), {
        font: { ...fontStyleSheet1, bold: true },
        border: borderStyle,
        alignment: alignmentCenterStyle,
        value: value
      })
    });
    // handle content section 
    const indexBodySection = indexHeaderSection + 1;
    let bodySections: any = [];
    let cell1 = '0:00';
    let cell2 = '0:00'
    courseInfo?.sections.forEach((section, idxSection) => {
      // handle time col A . ex: A2 = A1+B1
      if (idxSection !== 0) {
        cell1 = addTime(cell1, cell2);
      }
      cell2 = section?.subs[0]?.duration;
      bodySections.push(
        {
          colA: cell1,
          colB: section?.subs[0]?.duration.replace(/^0/, ''),
          colC: TYPE_SECTIONS[section?.type] ? TYPE_SECTIONS[section?.type] : TYPE_SECTIONS[0],
          colD: section?.subs[0]?.content ?? '',
          colE: section?.subs[0]?.method ?? '',
          colF: section?.subs[0]?.activity ?? ''
        }
      )
    });
    bodySections?.forEach((sec: any, index: number) => {
      const indBodySection = indexBodySection + index;
      // Add value into cell
      worksheet.addRow(sec)
      colHeaderSection.forEach(({ col }) => {
        const rowHeight = setRowHeightBasedOnText(findLongestStringLength([sec?.colD, sec?.colE, sec?.colF]), 15);
        worksheet.getRow(indBodySection).height = rowHeight;
        const cellHeaderSection = worksheet.getCell(`${col}${indBodySection}`);
        cellHeaderSection.font = { ...fontStyleSheet1 };
        cellHeaderSection.border = borderStyle;
        applyCellStyle(cellHeaderSection, {
          font: fontStyleSheet1,
          border: borderStyle,
        })
        if (col === 'B' || col === 'A') {
          cellHeaderSection.alignment = { vertical: 'top', horizontal: 'right', wrapText: true }
        } else if (col === 'C') {
          cellHeaderSection.alignment = alignmentStyle;
        } else {
          cellHeaderSection.alignment = { vertical: 'top', horizontal: 'left', wrapText: true }
        }
      });
    });
  }

  const exportTableToExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet1 = workbook.addWorksheet('提出されたCS');
    const worksheet2 = workbook.addWorksheet('総合評価');
    const worksheet3 = workbook.addWorksheet('ルーブリック ');
    handleSheet1(worksheet1);
    handleSheet2(worksheet2);
    handleSheet3(worksheet3);
    // Export the workbook to Excel file
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const currentDate = new Date();
      const day = String(currentDate.getMonth() + 1).padStart(2, '0');
      const month = String(currentDate.getDate()).padStart(2, '0');
      saveAs(blob, `${courseInfo?.title}_${month}${day}.xlsx`);
    });
  };

  return (
    <>
      <Loader isLoading={isLoading} isFullScreen={true}>
        <ListToastContainer />
        <div className="max-w-full">
          <TabLinks className="mb-4" />
          <div className="px-8 py-5 bg-white mb-4">
            <Heading className="text-blue-800">
              <span
                className="mr-2 inline-block align-middle cursor-pointer"
                onClick={() => navigate(rc(RouteKey.Home).path)}
              >
                <ArrowLeft color="#6599ED" />
              </span>
              授業の評価詳細
            </Heading>
            <div className="grid grid-cols-3 gap-4 mt-4">
              <div>
                <Label label="ID">
                  <TextField
                    type="text"
                    classNameChild="w-full block"
                    placeholder={id}
                    disabled
                  />
                </Label>
              </div>
              <div className="col-span-2">
                <Label label="タイトル">
                  <TextField
                    type="text"
                    classNameChild="w-full block"
                    placeholder={courseInfo?.title}
                    disabled
                  />
                </Label>
              </div>
            </div>
          </div>
          <div className="mx-8 mb-20">
            <div className="relative p-4 bg-white rounded border border-color-gray-40">
              <div className="overflow-hidden">
                {courseInfo &&
                  userID === courseInfo?.user?.id &&
                  statusShow?.includes(courseInfo?.status) && (
                    <div className="float-right ms-auto pl-4 flex gap-2">
                      {/* {courseInfo?.demo_info && (
                        <Button
                          type="button"
                          variant="primary"
                          iconPrefix={faDisplay}
                          size="small"
                          onClick={showModal}
                        >
                          模擬授業申請
                        </Button>
                      )} */}
                    </div>
                  )}
                <div className="float-right ms-auto pl-4 flex gap-x-2">
                  <Button
                    type="button"
                    variant="success"
                    size="small"
                    className="min-w-[142px] bg-green-100 rounded-lg border border-gray-300 !font-normal hover:bg-green-200"
                    onClick={() => exportTableToExcel()}
                  >
                    <div className='flex justify-center items-center'>
                      <img
                        src="/assets/excel_logo.svg"
                        alt="Excel Icon"
                        className="w-6 h-6 mr-2"
                      />
                      <div className='text-black'>
                        <p>Excel</p>
                        <p>エクスポート</p>
                      </div>
                    </div>
                  </Button>
                </div>
                <div>
                  <Tab
                    defaultActiveKey="2"
                    className="tab-evaluation-view shorter"
                  >
                    <TabPane tabKey="2" tab="提出されたCS">
                      <TabContent2 courseInfo={courseInfo} />
                    </TabPane>
                    <TabPane tabKey="1" tab="総合評価">
                      <TabContent1 courseInfo={courseInfo} />
                    </TabPane>
                    <TabPane tabKey="3" tab="評価内訳">
                      <TabContent3 courseInfo={courseInfo} criteria={criteria} />
                    </TabPane>
                  </Tab>
                </div>
              </div>
            </div>
          </div>
        </div>

        {id && courseInfo && (
          <RequestDemoDialog
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            id={id}
            courseInfo={courseInfo}
          // FormCSDemo={FormCSDemo}
          />
        )}
      </Loader>
    </>
  );
};
export default EvaluationView;

export const CusRadio = styled(Radio)`
  .ant-radio-input {
    opacity: 1;
  }
  .ant-radio-inner:after {
    background: #4a86e0;
  }

  .ant-radio-checked .ant-radio-inner,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-wrapper:hover,
  .ant-radio-input:focus .ant-radio-inner {
    border-color: #4a86e0;
  }
`;
