import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { BasicTable } from '../../../components/templates/BasicTable';
import type { TableProps } from 'antd';
import { Checkbox } from 'antd';
import { IEnhancedSupporter, ISupporterOfCourseDemo } from '../../../constants/types';
import { SUPPORTER_APPLICATION_ACCEPTED } from '../../../constants';

interface Props {
  dataTable: ISupporterOfCourseDemo[];
  selectedRowKeys: { [key: string]: React.Key[] };
  setSelectedRowKeys: Dispatch<SetStateAction<{ [key: string]: React.Key[] }>>;
  demoId: number;
  supporterAccept: ISupporterOfCourseDemo[];
  isSPAll: boolean;
}

const VideoAssignment = ({
  dataTable,
  selectedRowKeys,
  setSelectedRowKeys,
  demoId,
  isSPAll,
  supporterAccept,
}: Props) => {
  const params = useMemo(() => new URLSearchParams(window.location.search), []);
  const initialPage = parseInt(params.get('page') ?? '1', 10);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [supporterData, setSupporterData] = useState<IEnhancedSupporter[]>();
  useEffect(() => {
    const page = params.get('page');
    if (page) {
      setCurrentPage(parseInt(page, 10));
    }
  }, [params]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(prevState => ({
      ...prevState,
      [demoId]: newSelectedRowKeys,
    }));
  };


  const rowSelection = {
    selectedRowKeys: selectedRowKeys[demoId] ? selectedRowKeys[demoId] : [],
    onChange: (newSelectedRowKeys: React.Key[]) =>
      onSelectChange(newSelectedRowKeys),
    columnTitle: () => (
      <span className="text-nowrap">
        {
          dataTable?.length > 0 ?
          (
            <Checkbox
            id="checkall"
            indeterminate={
              selectedRowKeys[demoId] &&
              selectedRowKeys[demoId]?.length > 0 &&
              selectedRowKeys[demoId]?.length < supporterAccept?.length
            }
            checked={
              selectedRowKeys[demoId] &&
              selectedRowKeys[demoId].length > 0 &&
              selectedRowKeys[demoId]?.length === supporterAccept?.length
            }
            onChange={e =>
              onSelectChange(
              e.target.checked
              ? supporterAccept?.map(supporter => supporter.user.id)
              : []
              )
            }
            disabled={isSPAll}
          >
            <span className="text-color-gray-600">確定メール</span>
          </Checkbox>
          )
          :
          (
            <span className="text-color-gray-600">確定メール</span>
          )
        }
      </span>
    ),
    getCheckboxProps: (record: any) => ({
      disabled: record.status === SUPPORTER_APPLICATION_ACCEPTED,
    }),
  };

  const columns: TableProps<IEnhancedSupporter>['columns'] = [
    {
      title: 'サポーターID',
      dataIndex: 'id',
      key: 'id',
      render: id => (
        <div className="text-color-blue-700 font-medium">{id}</div>
      ),
    },
    {
      title: 'ユーザーID',
      dataIndex: 'username',
      key: 'username',
      render: username => (
        <div className="text-color-gray-80 font-semibold">{username}</div>
      ),
    },
    {
      title: '申請件数（未確定）',
      dataIndex: 'pending',
      key: 'pending',
    },
    {
      title: '確定件数',
      dataIndex: 'approved',
      key: 'approved',
    },
  ];

  useEffect(() => {
    const data = dataTable.map((demo, index) => {
      return {
        key: demo?.user?.id,
        id: demo?.user?.id,
        username: demo?.user?.username,
        pending: demo?.pending,
        approved: demo?.approved,
        status: demo?.status,
      };
    });
    setSupporterData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTable]);

  return (
    <>
      <div className="border border-color-blue-200 rounded p-4 mb-4 last:mb-0">
        <BasicTable
          columns={columns}
          data={supporterData}
          rowSelection={rowSelection}
          pageSize={10}
          totalData={dataTable.length}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPage={Math.ceil(dataTable?.length / 10)}
        />
      </div>
    </>
  );
};

export default VideoAssignment;
