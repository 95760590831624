import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import AuthService from '../../services/AuthService';
import TextFieldForm from '../../components/atoms/TextFieldForm';
import Button from '../../components/atoms/Button';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from '../../components/molecules/Modal';
import { RouteKey, rc } from '../../constants/router';
import { txtConfirmPasswordRequired } from '../../constants/message';

const ResetPassword = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [visible, setVisible] = useState<boolean>(false);
  const { uidb64, token } = useParams<{ uidb64: string; token: string }>();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<string>('');

  const newPassword = watch('new_password', '');

  useEffect(() => {
    if (AuthService.isAuthenticated()) {
      navigate(rc(RouteKey.Home).path);
    }
  }, [navigate]);

  useEffect(() => {
    if (!uidb64 || !token) {
      navigate('/not-found');
      return;
    }
    const reqToken = `${uidb64}/${token}`;
    AuthService.checkExpiration(reqToken).then(status => {
      if (status === 404) {
        navigate('/not-found');
      }
    });
  }, [navigate, token, uidb64]);

  const onSubmit = (data: any) => {
    if (!uidb64 || !token) {
      navigate('/not-found');
      return;
    }
    const reqToken = `${uidb64}/${token}`;
    AuthService.changePassword(
      data.new_password,
      data.confirm_password,
      reqToken,
    )
      .then(status => {
        if (status === 200) {
          setOpen(true);
          setModalContent(
            '新パスワードを再設定しました、再度ログインください。',
          );
        } else if (status === 404) {
          alert('トークンが無効または期限切れです。');
          navigate('/not-found');
        }
      })
      .catch(err => {
      });
  };

  return (
    <>
      <div style={{ backgroundPositionY: '50%' }} className="fixed top-1/2 left-1/2 w-full h-full opacity-100 transform -translate-x-1/2 -translate-y-1/2 scale-100 transition-all duration-300 ease-in-out">
        <img
          src="/assets/class_plan_eval_logo.jpg"
          alt="Background"
          className="absolute top-1/2 left-1/2 w-full h-full transform -translate-x-1/2 -translate-y-1/2 z-10 bg-cover"
        />
        <div id="gif" className='absolute left-1/2 transform -translate-x-[calc(50%+32px)] -translate-y-1/2 
          transition-all duration-300 ease-in-out z-20 h-full overflow-y-auto max-2xl:max-h-[calc(100vw*0.25)] max-h-[calc(100vw*0.15)] max-2xl:top-[60%] top-[50%]'
        >
          <div className="w-[calc(100vw*1/4)]">
            <h3 className={`text-heading-3 font-semibold text-black max-2xl:mb-2 2xl:mb-4 2xl:mt-2 max-2xl:text-[18px] 2xl:text-[20px]`}>
              パスワード再設定
            </h3>
            <div className={`text-xs text-black max-2xl:mb-2 mb-4 max-2xl:text-[10px]`}>
              新しいパスワードを入力し、再設定してください。
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextFieldForm
                className="mb-3"
                classNameChild="w-full !text-[16px]"
                placeholder="新しいパスワード"
                iconRight={visible ? faEyeSlash : faEye}
                onClickIconRight={() => setVisible(!visible)}
                canClickIconRight={true}
                type={visible ? 'text' : 'password'}
                {...register('new_password', {
                  required: '新パスワードを入力ください',
                  minLength: {
                    value: 6,
                    message: 'この項目は少なくとも6文字以上にしてください。',
                  },
                })}
                onerror={errors.new_password?.message ? true : false}
                errorMessage={errors.new_password?.message}
                isNoAuth={true}
              />
              <TextFieldForm
                className="mb-3"
                classNameChild="w-full !text-[16px]"
                placeholder="新しいパスワード確認"
                type={'password'}
                {...register('confirm_password', {
                  required: txtConfirmPasswordRequired,
                  minLength: {
                    value: 6,
                    message: 'この項目は少なくとも6文字以上にしてください。',
                  },
                  validate: value =>
                    value === newPassword ||
                    '新パスワード確認が正しくありません。',
                })}
                onerror={errors.confirm_password?.message ? true : false}
                errorMessage={errors.confirm_password?.message}
                isNoAuth={true}
              />
              <Button type="submit" variant="primary" className="w-full max-2xl:!py-[2px] !py-[4px]">
                パスワードを設定する
              </Button>
            </form>
          </div>
        </div>
      </div>

      <Modal open={open} onClose={() => setOpen(false)}>
        <div className="flex flex-col gap-4">
          <h1 className="text-heading-3 text-black">成功</h1>
          <hr className="border-t-solid border-1 border-grey mb-2" />
          <p className="mb-4">{modalContent}</p>
          <div className="flex flex-row justify-center">
            <button
              className="border border-neutral-300 rounded-lg py-1.5 px-10
               bg-blue-500 hover:bg-blue-600 text-white"
              onClick={() => {
                setOpen(false);
                navigate(rc(RouteKey.Login).path);
              }}
            >
              Ok
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default ResetPassword;
