import { useEffect, useState } from 'react';
import { ICriteriaSchema } from '../../../constants/types';
import CriteriaService from '../../../services/CriteriaService';
import { convertToWhiteCircledNumber } from '../../../utils/common';
import {
  RATING_EXEMPLARY,
  RATING_IMPROVEMENT,
  RATING_STANDARD,
} from '../../../constants';

const TabContent3 = ({ courseInfo, criteria }: any) => {
  const filteredCriteria = (typeId: number) => {
    return courseInfo?.criteria?.find((item: any) => item.type === typeId);
  };

  return (
    <>
      <div className="flex flex-col gap-4">
        <table className="border-separate rounded border-spacing-0 w-full border border-color-blue-100 text-sm table-simple">
          {criteria?.map((criter: any, index: any) => (
            <>
              <tr className="bg-color-blue-50 text-color-blue-600 font-semibold">
                <td className="border-r border-r-color-blue-100 p-4 h-14 align-middle">
                  {`評価基準 ${convertToWhiteCircledNumber(index + 1)}`}
                </td>
                <td
                  className="p-4 h-14 align-middle last:border-r-0"
                  colSpan={3}
                >
                  {criter?.name}
                </td>
              </tr>

              <tr>
                <td
                  width="16%"
                  className={`border-r border-r-color-blue-100 p-4 h-28 align-top text-color-blue-900`}
                >
                  評価
                </td>
                <td
                  width="28%"
                  className={`border-r border-r-color-blue-100 p-4 h-28 align-top text-color-blue-900 ${filteredCriteria(criter.id)?.rating === RATING_EXEMPLARY ? 'bg-color-green-50' : ''}`}
                >
                  {criter?.rating_label_1}
                </td>
                <td
                  width="28%"
                  className={`border-r border-r-color-blue-100 p-4 h-28 align-top text-color-blue-900 ${filteredCriteria(criter.id)?.rating === RATING_STANDARD ? 'bg-color-gray-30' : ''}`}
                >
                  {criter?.rating_label_2}
                </td>
                <td
                  width="28%"
                  className={`p-4 h-28 align-top text-color-blue-900  last:border-r-0 ${filteredCriteria(criter.id)?.rating === RATING_IMPROVEMENT ? 'bg-color-red-50' : ''}`}
                >
                  {criter?.rating_label_3}
                </td>
              </tr>

              <tr>
                <td className="border-r border-r-color-blue-100 p-4 h-36 align-top text-color-blue-900">
                  コメント・改修案
                </td>
                <td
                  className="p-4 h-36 align-top text-color-blue-900 last:border-r-0"
                  colSpan={4}
                >
                  {filteredCriteria(criter.id)?.comment}
                </td>
              </tr>
            </>
          ))}
        </table>
      </div>
    </>
  );
};
export default TabContent3;
