import PropTypes from 'prop-types';
import { displayTypeSection } from '../../utils/common';
import { useEffect, useState } from 'react';
import { MenuProps } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { BasicDropdown } from '../../components/molecules/Dropdown';
const items: MenuProps['items'] = [
    {
        label: '導入',
        key: 0,
    },
    {
        label: '展開',
        key: 1,
    },
    {
        label: 'まとめ',
        key: 2,
    },
];
const SelectOptionSection = ({ val, onChange, formData, index, isViewOnly }: any) => {
    const [textTypeSection, setTextTypeSection] = useState(displayTypeSection(formData, index, val));

    const handleDropdownItemClick = (e: any) => {
        onChange(Number(e.key));
    };

    useEffect(() => {
        setTextTypeSection(displayTypeSection(formData, index, val))
    }, [val, formData]);

    return (
        <>
            <BasicDropdown
                menu={{
                    items: items,
                    onClick: handleDropdownItemClick,
                }}
                trigger="click"
                placement="bottomLeft"
                disabled={isViewOnly}
            >
                <button
                    type="button"
                    className={`w-full block text-left border border-color-gray-03 rounded-md py-[2px] ps-4 pe-8 text-base text-color-gray-600 relative ${isViewOnly ? 'bg-color-gray-20' : ''}`}
                >
                    {textTypeSection}
                    {!isViewOnly && (
                        <>
                            <FontAwesomeIcon
                                icon={faChevronDown}
                                className="text-xxs absolute right-2.5 top-1/2 -translate-y-1/2"
                            />
                        </>
                    )}
                </button>
            </BasicDropdown>
        </>
    );
}

SelectOptionSection.propTypes = {
    onChange: PropTypes.func,
    val: PropTypes.any,
    formData: PropTypes.any,
    index: PropTypes.any,
    isViewOnly: PropTypes.bool
};

SelectOptionSection.defaultProps = {
    onChange: () => { },
    val: '',
    formData: {},
    index: 0,
    isViewOnly: false
};

export default SelectOptionSection;