import Button from '../../components/atoms/Button';
import { rc, RouteKey } from '../../constants/router';

import { faUser } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import AuthService from '../../services/AuthService';
import TextFieldForm from '../../components/atoms/TextFieldForm';
import { useEffect, useState } from 'react';
import Modal from '../../components/molecules/Modal';
import { inputPromptEmailOrUserID, txtNotificationCheckEmail } from '../../constants/message';
import { BasicModal } from '../../components/molecules/BasicModal';

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<string>('');
  const [isRequestError, setIsRequestError] = useState<boolean>(false);

  useEffect(() => {
    if (AuthService.isAuthenticated()) {
      navigate(rc(RouteKey.Home).path);
    }
  }, [navigate]);

  const onSubmit = async (data: any) => {
    AuthService.resetPassword(data.username).then(response => {
      if (response) {
        const res: any = response;
        if (res.status === 200) {
          setIsRequestError(false);
          setOpen(true);
          setModalContent(txtNotificationCheckEmail);
        } else {
          setIsRequestError(true);
        }
      }
    });
  };

  const isValidInput = (input: string): boolean => {
    // Regular expression pattern for email validation
    const emailPattern: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // Regular expression pattern for normal characters validation (no special characters, no spaces)
    const normalCharsPattern: RegExp = /^[a-zA-Z0-9]+$/;

    // Check if the input matches either email pattern or normal characters pattern
    return emailPattern.test(input) || normalCharsPattern.test(input);
  };

  return (
    <>
      <div style={{ backgroundPositionY: '50%' }} className="fixed top-1/2 left-1/2 w-full h-full opacity-100 transform -translate-x-1/2 -translate-y-1/2 scale-100 transition-all duration-300 ease-in-out">
        <img
          src="/assets/class_plan_eval_logo.jpg"
          alt="Background"
          className="absolute top-1/2 left-1/2 w-full h-full transform -translate-x-1/2 -translate-y-1/2 z-10 bg-cover"
        />
        <div id="gif" className='absolute left-1/2 transform -translate-x-[calc(50%+32px)] -translate-y-1/2 
        transition-all duration-300 ease-in-out z-20 h-full max-h-[calc(100vw*0.15)]
        overflow-y-auto'
          style={{ top: '50%'}}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="w-[calc(100vw*1/4)]">
              <h3 className="text-heading-3 font-semibold mt-4 mb-1 text-black max-2xl:text-[18px] 2xl:text-[20px]">
                パスワード再設定
              </h3>
              <div className="text-xs text-black max-2xl:text-[10px]">
                登録しているメールアドレスまたはユーザーIDを入力してください
              </div>
              <div className="text-xs text-black mb-4 max-2xl:text-[10px]">
                パスワード再設定ページのURLをメールにてお知らせ致します。
              </div>
              <TextFieldForm
                className={`max-2xl:mb-4 mb-8`}
                classNameChild="w-full !text-[16px]"
                placeholder="メールアドレスまたはユーザーID"
                iconLeft={faUser}
                {...register('username', {
                  required: inputPromptEmailOrUserID,
                  validate: (value: string) =>
                    isValidInput(value) || inputPromptEmailOrUserID,
                })}
                onerror={(errors.username?.message || isRequestError) ? true : false}
                errorMessage={isRequestError ? inputPromptEmailOrUserID : errors.username ? inputPromptEmailOrUserID : ''}
                isNoAuth={true}
              />
              <Button type="submit" variant="primary" className={`w-full max-2xl:!py-[2px] !py-[4px]`}>
                送信
              </Button>
            </div>
          </form>
        </div>
      </div>

      <Modal open={open} onClose={() => setOpen(false)}>
        <div className="flex flex-col gap-4 mt-2">
          <p className="mb-4">{modalContent}</p>
          <div className="flex flex-row justify-center">
            <button
              className="border border-neutral-300 rounded-lg py-1.5 px-10
               bg-blue-500 hover:bg-blue-600 text-white"
              onClick={() => {
                setOpen(false);
                navigate(rc(RouteKey.Login).path);
              }}
            >
              Ok
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default ForgotPassword;
