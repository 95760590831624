import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BasicTooltip from './Tooltips';

export const Label = ({
  children,
  className,
  label,
  textNote,
  required,
  textTooltip,
  iconTooltip,
  optional,
  collapse,
  isCollapse,
  setIsCollapse,
  onerror,
  isShowLabel
}: any) => {
  return (
    <>
      <div className={className}>
        <div className={`${collapse ? 'flex justify-between items-center' : ''}`}>
          {
            isShowLabel && (
              <label className="text-base text-color-gray-06 font-semibold block mb-1">
                {required && <span className="text-color-red-04">* </span>}
                {label}
                {textTooltip && (
                  <BasicTooltip trigger="hover" placement="top" text={textTooltip}>
                    <FontAwesomeIcon
                      className="text-color-gray-default ml-1"
                      icon={iconTooltip}
                    />
                  </BasicTooltip>
                )}
                {optional && (
                  <span className="text-color-gray-default ml-1 font-normal">
                    (optional)
                  </span>
                )}
              </label>
            )
          }
          {collapse && (
            <button
              type="button"
              className="inline-block text-center text-sm whitespace-nowrap mb-1 mr-1"
              onClick={() => {
                setIsCollapse(!isCollapse)
              }}
            >
              {
                isCollapse ? (
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.5" width="15" height="15" stroke="#234680" />
                    <path d="M2 7.65C2 7.29101 2.29101 7 2.65 7H13.85C14.209 7 14.5 7.29101 14.5 7.65C14.5 8.00898 14.209 8.3 13.85 8.3H2.65C2.29101 8.3 2 8.00898 2 7.65Z" fill="#234680" />
                  </svg>
                ) : (
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.5" width="15" height="15" stroke="#234680" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8 3C8.27614 3 8.5 3.22386 8.5 3.5V7.5H12.5C12.7761 7.5 13 7.72386 13 8C13 8.27614 12.7761 8.5 12.5 8.5H8.5V12.5C8.5 12.7761 8.27614 13 8 13C7.72386 13 7.5 12.7761 7.5 12.5V8.5H3.5C3.22386 8.5 3 8.27614 3 8C3 7.72386 3.22386 7.5 3.5 7.5H7.5V3.5C7.5 3.22386 7.72386 3 8 3Z" fill="#234680" />
                  </svg>

                )
              }
            </button>
          )}
        </div>
        {children}
        {textNote && (
          <div
            className={`text-sm ${onerror ? 'text-color-red-04' : 'text-color-gray-default'}`}
          >
            {textNote}
          </div>
        )}
      </div>
    </>
  );
};

Label.propTypes = {
  children: PropTypes.node,
  labelClassNameExtra: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  textTooltip: PropTypes.string,
  textNote: PropTypes.string,
  required: PropTypes.bool,
  optional: PropTypes.bool,
  onerror: PropTypes.bool,
  iconTooltip: PropTypes.object,
  collapse: PropTypes.bool,
  isCollapse: PropTypes.bool,
  setIsCollapse: PropTypes.func,
  isShowLabel: PropTypes.bool
};

Label.defaultProps = {
  labelClassNameExtra: '',
  className: '',
  required: false,
  optional: false,
  onerror: false,
  collapse: false,
  isCollapse: false,
  setIsCollapse: () => { },
  isShowLabel: true
};

export default Label;
