// import { Input } from 'antd';
// import { Button } from 'antd';
// import {
//   UserOutlined,
//   EyeTwoTone,
//   EyeInvisibleOutlined,
// } from '@ant-design/icons';
import Button from '../../components/atoms/Button';
import { rc, RouteKey } from '../../constants/router';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet-async';

import {
  faUser,
  faEye,
  faKey,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import AuthService from '../../services/AuthService';
import TextFieldForm from '../../components/atoms/TextFieldForm';
import { useState } from 'react';
import {
  getUrlRedirect,
  removeCountLoginPage,
  removeUrlRedirect,
} from '../../helper/localStorage';
import {
  errorLogin,
  pwRequired,
  usernameRequired,
} from '../../constants/message';
import { Loader } from '../../components/atoms/Loader';
import RegiterModal from './included/RegiterModal';
import { ToastContainer } from 'react-toastify';

const LoginPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [visible, setVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRequestError, setIsRequestError] = useState<boolean>(false);
  const [isOpenRegiter, setIsOpenRegiter] = useState(false);

  const onSubmit = (data: any) => {
    setIsRequestError(false);
    setIsLoading(true);
    AuthService.login(data.username, data.password)
      .then(status => {
        if (status) {
          const url = getUrlRedirect();
          if (url) {
            removeUrlRedirect();
            removeCountLoginPage();
            navigate(url);
          } else {
            navigate(rc(RouteKey.Home).path);
          }
        } else {
          setIsLoading(false);
          setIsRequestError(true);
        }
      })
      .catch(error => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Helmet>
        <title>Class Plan Eval</title>
        <meta name="description" content="Class Plan Eval" />
      </Helmet>
      <ToastContainer
        containerId={'register-account'}
        position="top-right"
        autoClose={5000}
        closeButton={false}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={true}
        theme="light"
      />
      <Loader isLoading={isLoading} isFullScreen={true} />
      <div style={{ backgroundPositionY: '50%' }} className="fixed top-1/2 left-1/2 w-full h-full opacity-100 transform -translate-x-1/2 -translate-y-1/2 scale-100 transition-all duration-300 ease-in-out">
        <img
          src="/assets/class_plan_eval_logo.jpg"
          alt="Background"
          className="absolute top-1/2 left-1/2 w-full h-full transform -translate-x-1/2 -translate-y-1/2 z-10 bg-cover"
        />
        <div id="gif" className={`absolute left-1/2 transform -translate-x-[calc(50%+32px)] -translate-y-1/2 
        transition-all duration-300 ease-in-out z-20 h-full overflow-y-auto max-h-[calc(100vw*0.15)] top-[49%]`}
        >
          <form onSubmit={handleSubmit(onSubmit)} className={`flex flex-col justify-center items-center max-2xl:mt-2 mt-6`}>
            <div className="w-[calc(100vw*1/4)]">
              {
                isRequestError && (
                  <>
                    <span className='text-red-600 text-sm'>
                      {errorLogin}
                    </span>
                  </>
                )
              }
              <TextFieldForm
                className={`max-2xl:mb-2 mb-3 ${isRequestError ? 'mt-2' : ''}`}
                classNameChild="w-full"
                placeholder="メールアドレスまたはユーザーID"
                autoComplete="username"
                iconLeft={faUser}
                {...register('username', {
                  required: usernameRequired,
                })}
                onerror={errors.username?.message ? true : false}
                errorMessage={errors.username?.message}
                isNoAuth={true}
              />
              <TextFieldForm
                className=""
                classNameChild="w-full"
                placeholder="パスワード"
                autoComplete="current-password"
                iconLeft={faKey}
                iconRight={visible ? faEyeSlash : faEye}
                onClickIconRight={() => setVisible(!visible)}
                canClickIconRight={true}
                type={visible ? 'text' : 'password'}
                {...register('password', {
                  required: pwRequired,
                })}
                onerror={errors.password?.message ? true : false}
                errorMessage={errors.password?.message}
                isNoAuth={true}
              />
              <Button type="submit" variant="primary" className={`w-full max-2xl:!py-[4px] !py-[6px] text-base ${errors.username?.message || errors.password?.message ? 'mt-4' : 'mt-3'}`}>
                ログイン
              </Button>
              <div className={`text-center text-xs max-2xl:mt-2 mt-4 max-2xl:text-[12px]`}>
                パスワードを忘れ方は
                <Link
                  to={rc(RouteKey.ForgotPassword).path}
                  className="text-color-red-500"
                >
                  こちら
                </Link>
              </div>

              <div className={`flex justify-center max-2xl:mt-2 mt-4`}>
                <Button type="button" variant="primary" size='medium' className={`text-base max-2xl:!py-[4px] !py-[6px]`} onClick={() => { setIsOpenRegiter(true) }}>
                  アカウント作成
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {
        isOpenRegiter && (
          <RegiterModal isOpenRegiter={isOpenRegiter} setIsOpenRegiter={setIsOpenRegiter} />
        )
      }
    </>
  );
};
export default LoginPage;
