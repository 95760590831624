import React, { useState, useEffect, createContext, useContext } from 'react';
import AuthService from '../services/AuthService';
import LoginPage from '../pages/LoginPage';
import { useLocation, useNavigate } from 'react-router-dom';
import { RouteKey, rc } from '../constants/router';
import UserInfoService from '../services/MyPageService';
import {
  getCountLoginPage,
  getProfile,
  removeCountLoginPage,
  removeUrlRedirect,
  setCountLoginPage,
  setPofile,
  setUrlRedirect,
} from '../helper/localStorage';

interface User {
  id: number;
  username: string;
  role: string;
  role_display: string;
  code?: string;
}

export interface UserContextType {
  currentUser: User | null;
  setCurrentUser: React.Dispatch<React.SetStateAction<User | null>>;
  userToken: string | null;
  setUserToken: React.Dispatch<React.SetStateAction<string | null>>;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [userToken, setUserToken] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const whitelist = [
    rc(RouteKey.ForgotPassword).main,
    rc(RouteKey.ResetPassword).main,
    '/not-found',
    rc(RouteKey.NotPermission).main,
  ];
  const routerLoginAutoRedirect = [rc(RouteKey.FeedbackForm).main];
  const isAllowed = whitelist.some(path => location.pathname.includes(path));
  const isRedirect = routerLoginAutoRedirect.some(path =>
    location.pathname.includes(path),
  );

  function checkSearchFormat(search: string): boolean {
    const regex = /code=.*&scope/;
    if (regex.test(search)) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    const checkLoggedIn = async () => {
      if (!AuthService.isAuthenticated()) {
        const path = location.pathname;
        const count = getCountLoginPage();

        if (!isAllowed) {
          if (count) {
            setCountLoginPage((Number(count) + 1).toString());
          }
          if (isRedirect) {
            setUrlRedirect(path);
            setCountLoginPage('1');
          } else {
            if (
              path.includes(rc(RouteKey.Login).path) &&
              Number(count) > 1
            ) {
              removeUrlRedirect();
              removeCountLoginPage();
            }
          }
          if(path === '/') {
            navigate(rc(RouteKey.HomePage).path);
          } else {
            navigate(rc(RouteKey.Login).path);
          }
        } else {
          removeUrlRedirect();
          if (count) {
            removeCountLoginPage();
          }
        }
      }
      setCurrentUser(AuthService.getUserLogin());
      setUserToken(AuthService.getTokenLogin());
    };

    checkLoggedIn();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, location.pathname]);

  useEffect(() => {
    if (checkSearchFormat(location?.search)) {
      const searchParams = new URLSearchParams(location.search);
      const code = searchParams.get('code');
      localStorage.setItem('code', code || '');
      navigate(`${rc(RouteKey.MyPage).path}?ggcalendar=true`);
    }
    if (
      AuthService.isAuthenticated() &&
      !isAllowed &&
      location.pathname === rc(RouteKey.Login).path &&
      !checkSearchFormat(location?.search)
    ) {
      navigate(rc(RouteKey.Home).path);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AuthService.getTokenLogin()]);

  useEffect(() => {
    const roleStorage = AuthService.getUserRole();
    if (roleStorage !== null && location.pathname !== '/') {
      UserInfoService.getUserInfo().then(r => {
        const { id, username, email, first_name, role, role_display, roles } =
          r;

        const user = {
          id,
          username,
          email,
          first_name,
          role,
          role_display,
          roles,
        };
        const rolesStorage = getProfile();
        if (rolesStorage && JSON.stringify(roles) !== JSON.stringify(JSON.parse(rolesStorage)?.roles)) {
          setPofile(JSON.stringify(user));
        }
      });
    }
  }, [location]);

  return (
    <UserContext.Provider
      value={{ currentUser, setCurrentUser, userToken, setUserToken }}
    >
      {(isAllowed && !AuthService.getTokenLogin()) || (location.pathname === '/' && !AuthService.getTokenLogin())
        ? children : (AuthService.getTokenLogin() ? children : <LoginPage />)}
    </UserContext.Provider>
  );
};

export const useUser = (): UserContextType => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};

export default UserContext;
