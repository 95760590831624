import { Heading } from '../../components/templates/Heading';
import { TabLinks } from '../../components/templates/TabLinks';
import { BasicTable } from '../../components/templates/BasicTable';
import type { TableProps } from 'antd';
import { useEffect, useState } from 'react';
import AuthService from '../../services/AuthService';
import { CS_ROLES, dateFormat, timeFormat } from '../../constants';
import { useLocation, useNavigate } from 'react-router-dom';
import SupportSideService, {
  HistorySupport,
  HistorySupportRecord,
} from '../../services/SupportSideService';
import { Loader } from '../../components/atoms/Loader';
import { checkDateDeadline } from '../../utils/common';
import moment from 'moment';
import dayjs from 'dayjs';

const columns: TableProps<HistorySupportRecord>['columns'] = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '授業タイトル',
    dataIndex: 'course',
    key: 'course',
    render: course => (
      <div className="text-color-blue-700 font-medium">{course.title}</div>
    ),
  },
  {
    title: '模擬授業実施日',
    dataIndex: 'start_at',
    key: 'start_at',
    render: (start_at: any, data: any) => {
      let demo_type = data.demo_type;
      const startMoment = moment(start_at);
      const endMoment = moment(data.end_at);
      const formattedDate = startMoment?.format(dateFormat);
      const formattedStartTime = startMoment?.format(`　${timeFormat}`);
      const formattedEndTime = endMoment?.format(timeFormat);
      if (demo_type === 1) {
        return (
          <div className="font-semibold">
            <span>{formattedDate}</span>{' '}
            <span className="text-color-blue-800">
              <span>{formattedStartTime}</span> -{' '}
              <span>{formattedEndTime}</span>
            </span>
          </div>
        );
      }
      return (
        <div className="font-semibold">
          <span>{dayjs(formattedDate).format(dateFormat)}まで</span>{' '}
        </div>
      );
    },
  },
  {
    title: '授業タイプ',
    dataIndex: 'demo_type_display',
    key: 'demo_type_display',
  },
  {
    title: 'フィードバック提出日',
    dataIndex: 'fb_deadline',
    key: 'fb_deadline',
    render: fb_deadline => moment(fb_deadline).format(dateFormat),
  },
  {
    title: '模擬授業をフィードバック',
    dataIndex: 'feedback_url',
    key: 'feedback_url',
    className: '!text-center',
    render: (feedback_url, data) => (
      <div className="grid justify-center" key={feedback_url}>
        {
          data.is_submitted || data.status === 4 ?
            (
              <>
                <p>FB済</p>
              </>
            )
            :
            (checkDateDeadline(data?.fb_deadline, false)
              ?
              (
                <>
                  <p>FB期限切れ</p>
                </>
              )
              :
              (
                <a href={feedback_url} target="_blank" rel="noreferrer">
                  <button
                    type="button"
                    className="w-[80px] inline-block text-sm font-semibold py-0.5 px-3 rounded text-center bg-color-blue-500 text-white hover:bg-color-blue-700 hover:text-white focus:bg-color-blue-500 focus:text-white disabled:bg-color-blue-200 disabled:text-color-gray-01 disabled:cursor-not-allowed"
                  >
                    FBする
                  </button>
                </a>
              )
            )
        }
      </div>
    ),
  },
];

const ApplyHistory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(window.location.search);
  const [historySupport, setHistorySupport] = useState<
    HistorySupport | undefined
  >(undefined);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState(false);
  const reloadData = async() => {
    await SupportSideService.getHistorySupport(Number(params?.get('page')) || 1).then(response => {
      setIsLoading(false);
      setHistorySupport(response);
    });
  }
  useEffect(() => {
    let role = AuthService.getUserRole();
    if (!role || role === CS_ROLES.user) {
      navigate('/not-found');
      return;
    }
    setIsLoading(true);
    reloadData();
  }, [location]);

  return (
    <>
      <div className="max-w-full">
        <TabLinks className="mb-4" />
        <div className="px-8 mb-24">
          <div className="border border-color-gray-40 bg-white p-4 rounded">
            <Heading className="mb-4 text-color-blue-700">
              サポーター履歴
            </Heading>
            <Loader isLoading={isLoading}>
              <BasicTable
                columns={columns}
                data={historySupport?.data}
                pageSize={historySupport?.page_size}
                totalData={historySupport?.total_records}
                currentPage={historySupport?.current_page}
                setCurrentPage={setCurrentPage}
                totalPage={historySupport?.total_page}
              />
            </Loader>
          </div>
        </div>
      </div>
    </>
  );
};
export default ApplyHistory;
